
import Chip from "@mui/material/Chip";
import { styled } from "@mui/material/styles"

interface ComponentProps {
    tagProps: {
        className: string;
        disabled: boolean;
        'data-tag-index': number;
        tabIndex: -1;
        onDelete: (event: any) => void;
    },
    label: string,
    color?: { bg?: string, text?: string },
    onChange?: () => void,
    children?: React.ReactNode,
}

export default function CustomDeletedChip({ label, color, tagProps }: ComponentProps) {
    return (<CustomChip label={label} variant="outlined" {...tagProps} sx={{
        backgroundColor: color?.text ?? "rgba(0, 0, 0, 0.5)",
        color: color?.text,
        borderColor: color?.text,
        borderWidth: 1.5,
        '& .MuiChip-label': {
            color: "#fff",
            fontFamily: 'Zain',
            fontSize: 16,
        },
        '& .MuiChip-deleteIcon': {
            color: "rgba(255, 255, 255, 0.7)"
        },
    }} />);
};

const CustomChip = styled(Chip)(() => ({
    height: 30,
    borderColor: 'gray',
    borderWidth: 1,
    borderRadius: 15,
    padding: 4
}));