import { IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';

type Props = {
    editFn(): void;
}

const EditIconButton: React.FC<Props> = ({editFn}) => {

    return <IconButton sx={{
        marginTop: '20px',
        width: '40px', 
        height: '40px', 
  }} onClick={editFn}>
        <EditIcon fontSize="medium"/>
    </IconButton>

}

export default EditIconButton;