import React from 'react';
import { Box } from '@mui/material'; 

type Props = {
    children:  React.ReactNode,
    sx?: {} 
}

const MainBackgroundContainer: React.FC<Props> = ({children, sx = {}}) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        background: '#FAFAFA',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        display: 'inline-flex',
        paddingTop: '16px',
        paddingRight: '1px',
        ...sx, 
      }}
    >
      {children}
    </Box>
  );
};

export default MainBackgroundContainer;