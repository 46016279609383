import { Box, Modal } from "@mui/material";
import ReturnButton from "../components/HeaderListsCommonComps/ReturnButtonIcon";
import { useLocation } from "react-router-dom";
import HeaderButtonCreateEvent from "../components/HeaderListsCommonComps/HeaderButtonCreateEvent";
import { useState } from "react";
import { newEventTabConfig } from "../configCommon/tabs-config";
import TopHeaderWithoutTabs from "../components/tabs/TopHeaderWithoutTabs";
import Evnt from "../model/Event/Evnt";
import WarningWindowUnsaved from "../pages/Events/EventActions/CreateEventBasic/WarningWindowUnsaved";
import { useTranslation } from 'react-i18next';

type Props = {
  redirectToDetailsEvent():void;
}

const NewEventHeader: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  
  const initialEvent: Evnt = {
    nameOfEvent: '',
    dateAndTimeStart: null,
    dateAndTimeEnd: null,
    eventType: '',
    mainGuest: '',
    location: '',
    description: ''
  };

  const [evnt, setEvnt] = useState<Evnt>(initialEvent);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const showSaveButton = false;
  const showCreateEventButton = true;

  const location = useLocation();
  const backToTheRootPathFn = () => {
    const rootPath = location.pathname.split('/')[1];
    window.location.href = `/${rootPath}`;
  };

  const checkForUnsavedChanges = (ev: any) => {
    let hasUnsavedChanges = false;
    if (ev) {
      for (const val of Object.values(ev)) {
        if (val) {
          hasUnsavedChanges = true;
          break;
        }
      }
      if (!hasUnsavedChanges) {
        backToTheRootPathFn();
      } else {
        setOpenWarningModal(true);
      }
    } else {
      backToTheRootPathFn();
    }
  };

  const returnFn = () => {
    checkForUnsavedChanges(evnt);
  };

  function onClickDeleteFn() {
    setOpenWarningModal(false);
    backToTheRootPathFn();
  }

  function onClickStayHereFn(event: any) {
    event.preventDefault();
    setOpenWarningModal(false)
  }


  return (<>
    <TopHeaderWithoutTabs
      pageName={t('newev.header.name.createevent')} 
      returnButton={<ReturnButton onClickFn={returnFn} />}
      pageTopButtons={
        <HeaderButtonCreateEvent buttonName={t('newev.header.button.createevent')} 
        isButtonDisabled={!showCreateEventButton}
        />
      }
      components={newEventTabConfig.components(props.redirectToDetailsEvent, showSaveButton, evnt, setEvnt)}
    />
    <Modal
      open={openWarningModal}
      onClose={() => setOpenWarningModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleModal}>
        {openWarningModal && (
          <WarningWindowUnsaved
            onClickDeleteChanges={onClickDeleteFn}
            onClickStayHere={onClickStayHereFn}
            onClose={onClickStayHereFn} />
        )}
      </Box>
    </Modal>
  </>
  );

}

const styleModal = {
  position: 'absolute' as 'absolute',
  top: '35%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '16px',
  padding: '0px',
};

export default NewEventHeader;

