import {createSlice} from '@reduxjs/toolkit';
import User from '../../model/User';
const AUTH_ITEM = process.env.REACT_APP_LS_AUTH_ITEM as string;


function getUserData(): User {
    const userDataJson = localStorage.getItem(AUTH_ITEM);
    let res: User = null;
    if(userDataJson) {
        res = JSON.parse(userDataJson);
    }
    return res;
}
const initialState: {user: User} = {
user: getUserData()
}
const authSlice = createSlice({
    initialState,
    name: "authState",
    reducers: {
        set: (state, data) => {
            if (data.payload) {
                localStorage.setItem(AUTH_ITEM, JSON.stringify(data.payload));
                state.user = data.payload;
            }
        },
        reset: (state) => {
            state.user = null;
            localStorage.removeItem(AUTH_ITEM);
        }
    }
});
export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;