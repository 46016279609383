import ContactsEventsReuseTabs from "../components/tabs/ContactsEventsReuseTabs";
import {eventsTabsConfig } from "../configCommon/tabs-config";
import HeaderEventsListButton from "../components/HeaderListsCommonComps/HeaderEventsListButtons";
import { useTranslation } from 'react-i18next';

type Props = {
  onClickFn(): void;
}

const EventsHeader: React.FC<Props> = (props) => {
  const { t } = useTranslation();

return (
<ContactsEventsReuseTabs
pageName={t('events.header.name.eventslist')} 
pageTopButtons={<HeaderEventsListButton disabled={false} buttonName={t('events.header.button.newevent')} onClickFn={props.onClickFn} />}
basePath={eventsTabsConfig.basePath}
tabNames={eventsTabsConfig.tabNames}
labels={eventsTabsConfig.labels}
components={eventsTabsConfig.components} />
);


}

export default EventsHeader;