import ContactsEventsReuseTabs from "../components/tabs/ContactsEventsReuseTabs";
import { eventsDetailsTabsConfig } from "../configCommon/tabs-config";
import ReturnButton from "../components/HeaderListsCommonComps/ReturnButtonIcon";
import { useLocation } from "react-router-dom";
import { useSelectorEvent } from "../redux/store";
import { eventsService } from "../configCommon/service-config";
import { useEffect, useState } from "react";
import Evnt from "../model/Event/Evnt";
import { useDispatch } from "react-redux";
import { eventActions } from "../redux/slices/eventSlice";


const EventDetailHeader: React.FC= () => {
    const [evApi, setEvApi] = useState<Evnt|null>(null);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const idPath = location.pathname.split('/')[3];
    const curEvent = useSelectorEvent();
    const dispatch = useDispatch();
   // console.log('redux id: '+(curEvent._id));

  const getCurEvApi = async () => {
        try {
          const id: any = idPath ? idPath : curEvent._id;
          const event = await eventsService.getEventById(id);
          setEvApi(event);
          dispatch(eventActions.set(event)); 
        } catch (error) {
          console.error('Error fetching event:', error); 
        } finally {
          setLoading(false); 
        }
   
  }

  useEffect(() => {
    getCurEvApi();
  }, []);
  
    function returnFn() {
        const rootPath = location.pathname.split('/')[1];
        window.location.href = `/${rootPath}`
    };

    if (loading) {
        return <p>Loading event details...</p>; 
      }
      if (!curEvent?.nameOfEvent && !evApi?.nameOfEvent) {
        return <p>No event details available</p>; 
      }


    return (<>
        <ContactsEventsReuseTabs
            returnButton={<ReturnButton onClickFn={returnFn}/>}
            pageName={curEvent?.nameOfEvent || evApi?.nameOfEvent}
            basePath={eventsDetailsTabsConfig.basePath}
            tabNames={eventsDetailsTabsConfig.tabNames}
            labels={eventsDetailsTabsConfig.labels}
            components={eventsDetailsTabsConfig.components} />
            </>
    );
}

export default EventDetailHeader;


