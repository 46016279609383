/**
 * @author Nick M.
 * @create 2024-09-13
 * @desc Default search input component.
 */

import React from 'react';
import { TextField, InputAdornment, Button } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { SearchRounded } from '@mui/icons-material';
import SearchInputML from '../../model/SearchInputML';

interface ComponentProps {
    title?: { label: string, placeholder: string },
    listData: SearchInputML[],
    selectedOptions?: SearchInputML[],
    onChange?: (event: any, newValue: any) => void
}

export type ComponentRef = {
    removeOptionBy: (option: SearchInputML) => void;
};

const SearchInput = React.forwardRef<ComponentRef, ComponentProps>(({ title, listData, selectedOptions, onChange }: ComponentProps, ref) => {

    const selecteed = listData.filter((option) => selectedOptions?.some(group => group.id === option.id));
    const [value, setValue] = React.useState<SearchInputML[]>(selecteed ?? []);

    React.useImperativeHandle(ref, () => ({
        removeOptionBy(option: SearchInputML) {
            const newList = value.filter((item) => item.id !== option.id);
            setValue(newList);
            onChange && onChange(null, newList)
        },
    }));

    return (
        <Autocomplete
            multiple
            id="multiple-tag"
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
                onChange && onChange(event, newValue)
            }}
            filterSelectedOptions
            options={listData}
            getOptionLabel={(option) => option.title}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => { return (<></>); })
            }
            renderInput={(params) => (
                <TextField
                    {...params} label={title?.label} placeholder={title?.placeholder} margin="dense"
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchRounded />
                            </InputAdornment>
                        )
                    }}
                />
            )}
        />
    );
});

export default SearchInput;