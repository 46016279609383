
import * as React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import ContactsGroupML from '../../../../model/ContactsGroups/ContactsGroupML';
import ContactGroupsRow from '../../../../components/CustomDataGrid/Rows/ContactGroupsRow';
import SimpleNumberRow from '../../../../components/CustomDataGrid/Rows/SimpleNumberRow';
import ParticipantsRow from '../../../../components/CustomDataGrid/Rows/ParticipantsRow';
import { useTranslation } from 'react-i18next';
import SingleAddItemActionCell from '../../../../components/CustomDataGrid/EventsRows/SingleAddItemActionCell';
import CustomDataGridTypeContacts from '../../../../components/CustomDataGrid/CustomDataGridTypeContacts';

interface Props {
  groups: ContactsGroupML[];
  onAddGroup: (group: ContactsGroupML) => void;
}

const Groups: React.FC<Props> = ({ groups, onAddGroup }) => {

    const { t } = useTranslation();

    const columns: GridColDef[] = [
        ContactGroupsRow({
          fId: 'groupData',
          fFlex: 0.1,
          columnName: t('groups.table.column.name.group.name'),
          marginL: 1}),
        SimpleNumberRow({
          fId: 'contactsCount',
          fFlex: 0.06,
          columnName: t('groups.table.column.name.number.contacts') }),
        ParticipantsRow({
          fId: 'contacts',
          fFlex: 0.4,
          columnName: t('groups.table.column.name.participants') }),
        SingleAddItemActionCell({
          fId: "addActions",
          fFlex: 0.035,
      })
  ];

    const rows = groups?.map((group) => {
    const contactsNames = group.contacts?.slice(0, 20)?.map((contact) => { return contact?.full_name }).join(', ')

        return {
            id: group._id,
            groupData: [group],
            contactsCount: group.contacts?.length ?? 0,
            contacts: contactsNames,
            addActions: {
              addfunc: () => onAddGroup(group),
              model: group
            }
           }
    });

    return (<>
            <CustomDataGridTypeContacts
                rows={rows}
                columns={columns}
          />
      </>
    );
}


export default Groups;

