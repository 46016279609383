/**
 * @author Nick M.
 * @create date 2024-09-16
 * @desc Contacts groups lists
 */

import * as React from 'react';
import { GridColDef, GridToolbarContainer, GridSlots } from '@mui/x-data-grid';
import { Box, TextField } from '@mui/material';
import ContactsGroupML from '../../model/ContactsGroups/ContactsGroupML';
import { NetworkService } from '../../configCommon/service-config';
import GroupModalView from './GroupModalView/GroupModalView';
import CustomDataGridType1 from '../../components/CustomDataGrid/CustomDataGridType1';
import ContactGroupsRow from '../../components/CustomDataGrid/Rows/ContactGroupsRow';
import SingleEditActionRow from '../../components/CustomDataGrid/Rows/SingleEditActionRow';
import SimpleNumberRow from '../../components/CustomDataGrid/Rows/SimpleNumberRow';
import ParticipantsRow from '../../components/CustomDataGrid/Rows/ParticipantsRow';
import { useTranslation } from 'react-i18next';

export default function GroupsLists() {

    const { t } = useTranslation();


    const columns: GridColDef[] = [
        ContactGroupsRow({ fId: 'groupData', fFlex: 0.1, columnName: t('groups.table.column.name.group.name'), marginL: 1 }),
        SimpleNumberRow({ fId: 'contactsCount', fFlex: 0.06, columnName: t('groups.table.column.name.number.contacts') }),
        ParticipantsRow({ fId: 'contacts', fFlex: 0.4, columnName: t('groups.table.column.name.participants') }),
        SingleEditActionRow({ fId: 'actions', fFlex: 0.035 })
    ];


    const [groups, setGroups] = React.useState<ContactsGroupML[] | null>(null);
    const [networkError, setNetworkError] = React.useState<string | unknown>(null);
    const [loading, setLoading] = React.useState<boolean>(true);

    const [open, setOpen] = React.useState<ContactsGroupML | undefined>(undefined);
    const handleClose = (group?: ContactsGroupML) => {
        if (group && groups) {
            if (groups.findIndex(c => c._id === group._id) !== -1) {
                groups[groups.findIndex(c => c._id === group._id)] = group;
            }
        }
        setOpen(undefined)
    };

    // Handle changes to selected contact groups
    const handleEditGroup = (group: ContactsGroupML) => {
        setOpen(group)
    };

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch both contact groups and prefixes (concurrently)
                const [groups] = await Promise.all([
                    NetworkService.getAllContactsGroups(true)
                ]);
                // Update state with the fetched data
                setGroups(groups);
            } catch (error) {
                setNetworkError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const rows = groups?.map((group) => {

        const contactsNames = group.contacts?.slice(0, 20)?.map((contact) => { return contact?.full_name }).join(', ')

        return {
            id: group._id,
            groupData: [group],
            contactsCount: group.contacts?.length ?? 0,
            contacts: contactsNames,
            actions: { 'func': handleEditGroup, 'model': group }
        }
    });

    return (
        <Box sx={mainContainer}>
            <CustomDataGridType1
                rows={rows}
                columns={columns}
                slots={{ toolbar: SearchToolbar as GridSlots['toolbar'] }}
            />
            {open && <GroupModalView open={(open !== undefined)} group={open} handleClose={handleClose} />}
        </Box >
    );

    // ------------ Top search tool bar for the data grid ------------
    interface SearchToolbarProps {
        // setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
        // setRowModesModel: (
        //     newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
        // ) => void;
    }

    function SearchToolbar(props: SearchToolbarProps) {
        return (
            <GridToolbarContainer>
                <Box sx={{ padding: 1.5, width: 300 }}>
                    <TextField name='group_name' label={t('groups.list.search.input.label')} placeholder={t('groups.list.search.input.plhd')} variant="outlined" fullWidth size="medium" type='text' />
                </Box>
            </GridToolbarContainer>
        );
    }

}

const mainContainer = {
    position: 'relative', height: '100vh', border: '32px solid #FAFAFA'
}