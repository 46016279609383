import * as React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import Evnt from '../../../../model/Event/Evnt'
import { useCallback, useEffect, useMemo, useState } from 'react';
import { eventsService } from '../../../../configCommon/service-config';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration'
import SingleTextCell from '../../../../components/CustomDataGrid/EventsRows/SingleTextCell';
import CustomDataGridTypeEvents from '../../../../components/CustomDataGrid/CustomDataGridTypeEvents';
import SearchTool from './SearchTool';
import MainBackgroundContainer from '../../CommonComponentsUI/MainBackgroundContainer';
import SingleRedirectActionCell from '../../../../components/CustomDataGrid/EventsRows/SingleRedirectActionCell';
import { useDispatch } from 'react-redux';
import { eventActions } from '../../../../redux/slices/eventSlice';
import { useNavigate } from 'react-router-dom';
import { hhPhotosMap } from '../../../../configCommon/hh-photos-config';
import AvatarHHCell from '../../../../components/CustomDataGrid/EventsRows/AvatarHHCell';
import SingleDateCell from '../../../../components/CustomDataGrid/EventsRows/SingleDateCell';
import { useSelectorAuth } from '../../../../redux/store';
dayjs.extend(utc);
dayjs.extend(duration);
const DEFAULT_EVENTS_DURATION = Number(process.env.REACT_APP_CONFIGURE_DEFAULT_EVENTS_DURATION);

type Props = {
    past: boolean;
}


const EventsTable: React.FC<Props> = ({ past }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const columns: GridColDef[] = [
        SingleTextCell({ fId: 'eventName', fFlex: 0.6, columnName: t("events.table.column.name.eventname"), marginL: 1 }),
        SingleDateCell({ fId: 'dateAndTimeStart', fFlex: 0.2, columnName: t("events.table.column.name.date"), marginL: 1 }),
       // SingleTextCell({ fId: 'dateAndTimeStart', fFlex: 0.2, columnName: t("events.table.column.name.date"), marginL: 1 }),
        AvatarHHCell({fId: 'host', fFlex: 0.75, columnName: t('table.page.column.mainguest'), marginL: 1}),
        //SingleTextCell({ fId: 'mainGuest', fFlex: 0.6, columnName: t('table.page.column.mainguest'), marginL: 1 }),
      
        SingleRedirectActionCell({ fId: 'actions', fFlex: 0.035 })
];

    const [events, setEvents] = useState<Evnt[] | null>(null);
    const [filteredEvents, setFilteredEvents] = useState<Evnt[] | null>(null);
    const [searchEvName, setSearchEvName] = useState<string>('');
    const [searchGuest, setSearchGuest] = useState<string>('');
    const navigate = useNavigate();
    const curUser = useSelectorAuth();

    const toEventDetails = (ev: Evnt) => {
        dispatch(eventActions.set(ev));
        window.location.href = (`/events/details/${ev._id}`);
};

const [allGuests, setAllMainGuests] = useState<any[]>([]);

const fetchData = async () => {
    try {
    const allMainGuests = await eventsService.getAllMainGuests();

    if (curUser?.pddUser == '0') {
       const mg = allMainGuests.filter((_, i) => i == 1)
        setAllMainGuests(mg)
            
    } else {
        setAllMainGuests(allMainGuests); 
    }
     } catch (error) {
      console.error('Error fetching data:', error)  
    }
}

// useEffect(() => {
//     fetchData();
//   }, []); 
fetchData();


    useEffect(() => {
        const fetchData = async () => {
            try {
                const allEvents : any = await eventsService.getAllEvents();
                if (allEvents.message) {
                    navigate('/')
                    return;
            }
           // setEvents(allEvents)

            // if (curUser?.pddUser == false) {
            //     const nonPddEvents = allEvents.filter((ev:any) => ev.mainGuest == allGuests[0]._id);
            //     setEvents(nonPddEvents);
                
            // } 


    const dateAndTimeNow = dayjs.utc();
                if (past) {
                // if (curUser?.pddUser == false) {
                // const nonPddEvents = events!.filter((ev:any) => ev.mainGuest == allGuests[1]._id);
                //  setEvents(nonPddEvents);
                // } 
                        const pastEvents = allEvents.filter((ev: Evnt) => {
                        const eventEndLocal = dayjs(ev.dateAndTimeEnd).local();
                        const isNationalHoliday = ev.eventType === 'National Holidays';

                        if (isNationalHoliday) {
                            const endOfDay = eventEndLocal.endOf('day');
                            return dateAndTimeNow.isAfter(endOfDay);
                        }
                        return (
                            dateAndTimeNow.isAfter(dayjs.utc(ev.dateAndTimeEnd))
                    )
                        // ((ev.dateAndTimeEnd ?
                        //         dateAndTimeNow.isAfter(dayjs.utc(ev.dateAndTimeEnd)) :
                        //         dateAndTimeNow.isAfter(dayjs(ev.dateAndTimeStart).add(DEFAULT_EVENTS_DURATION, 'hour'))));
                    });
                    setEvents(pastEvents);
                    setFilteredEvents(pastEvents);
                } else {
                    
                        // if (curUser?.pddUser == false) {
                        // const nonPddEvents = events!.filter((ev:any) => ev.mainGuest == allGuests[1]._id);
                        //  setEvents(nonPddEvents);
                        // } 
                    const upcomingEvents = allEvents.filter((ev: Evnt) => {
                        const isBeforeStart = dateAndTimeNow.isBefore(ev.dateAndTimeStart);
                            const isBeforeEnd = dateAndTimeNow.isBefore(dayjs(ev.dateAndTimeEnd));
                            const isTodaySameDay = ev.eventType === 'National Holidays' && dayjs(ev.dateAndTimeEnd).local().isSame(dayjs().local(), 'day');
                            return isBeforeStart || isBeforeEnd || isTodaySameDay;
                           // dateAndTimeNow.isBefore((ev.dateAndTimeStart)) ||
                            // dateAndTimeNow.isBefore(dayjs(ev.dateAndTimeStart).add(DEFAULT_EVENTS_DURATION, 'hour')) ||
                           //  dateAndTimeNow.isBefore(dayjs(ev.dateAndTimeEnd)) ||
                            // || 
                        //ev.eventType == 'National Holidays' && dayjs(ev.dateAndTimeEnd).local() == dayjs.locale() ? 
                    });
                    setEvents(upcomingEvents);
                    setFilteredEvents(upcomingEvents);
                }
            } catch (error) {
                throw error;
            }
        };

        fetchData();
    }, []);

    const filterEvents = useCallback(() => {
        if (events) {
            let filtered = events;
            if (searchEvName) {
                const lowerCaseSearch = searchEvName.toLowerCase();
                filtered = filtered.filter((ev) => {
                    const eventNameMatches = ev.nameOfEvent.toLowerCase().includes(lowerCaseSearch);
                    const formattedDate = dayjs(ev.dateAndTimeStart).format('DD MMMM, YY').toLowerCase();
                    const dateMatches = formattedDate.includes(lowerCaseSearch);
                    return eventNameMatches || dateMatches;
                });
            }
            if (searchGuest && searchGuest !== 'all') {
                const guest = allGuests.find(item => item.nameEn == searchGuest);
              filtered = filtered.filter((ev) =>
                    ev.mainGuest?.includes( guest._id)
                );
            }
            setFilteredEvents(filtered);
        }
    }, [events, searchEvName, searchGuest]);

    useEffect(() => {
       filterEvents();
    }, [filterEvents]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchEvName(e.target.value);
    };

    const handleFilterGuest = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSearchGuest(e.target.value);
    };


    const getGuestName = (event: Evnt) => {          
         const ev = events!.find((e) => e._id === event._id && e.mainGuest === event.mainGuest);
         const guest = allGuests.find((g) => g._id === ev?.mainGuest);
           if (ev && guest) {
           return guest.nameAr;
           } else {
           return ''
       }
   }

   const getAvatar = (mainGuestId: string|undefined) => {
    if (mainGuestId) {
        const guest = allGuests.find((g) => g._id === mainGuestId);
        if (guest) {
        const av = hhPhotosMap.get(guest.avatar);
        return av;
       } 
    } else {
        return undefined;
    }
   }
          
    const getRows =  () => {
        return filteredEvents?.map((ev) => {
            const hostName = getGuestName(ev);
            const ava = getAvatar(ev.mainGuest);
            if (hostName && ava) {

                return {
                    id: ev._id,
                    eventName: ev.nameOfEvent,
                    dateAndTimeStart: dayjs.utc(ev.dateAndTimeStart).local().toDate(),
                    //dateAndTimeStart: dayjs.utc(ev.dateAndTimeStart).local().format('DD.MM.YYYY')
                    host: {
                        mainGuest: hostName, 
                        avatar: ava        
                    },     
                    // ev.dateAndTimeStart
                    //     ? dayjs.utc(ev.dateAndTimeStart).local().format('DD MMMM, YY')
                    //     : '-',
                    guests: ev.guests?.length ?? '-',
                    status: ev.status ?? '-',
                    actions: { 'func': toEventDetails, 'model': ev }
                };
              }
            return null;
         }).filter((row) => row !== null);
            }

            //const rows = getRows();

 
    return (<>
        <MainBackgroundContainer sx={{ position: 'relative' }}>
            <SearchTool handleSearch={handleSearchChange} searchParam={searchEvName} handleFilterGuest={handleFilterGuest} searchParamGuest={searchGuest} />
            <CustomDataGridTypeEvents
                rows={getRows()}
                columns={columns}
            />
        </MainBackgroundContainer>
    </>
     )
}

export default EventsTable;

