import { useEffect, useState } from 'react';
import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import EventsHeader from '../../Headers/EventsHeader';
import logo from '../../assets/Logos/PDDLogoSvgSmall.svg';
import { CalendarTodayRounded, ContactsRounded, LogoutRounded, CalendarMonthRounded} from '@mui/icons-material';
import ContactsHeader from '../../Headers/ContactsHeader';
import NewEventHeader from '../../Headers/NewEventHeader';
import EventDetailHeader from '../../Headers/EventDetailHeader';
import { useTranslation } from 'react-i18next';
import { useSelectorEvent } from '../../redux/store';
import HeaderWithoutTabs from '../../Headers/HeaderWithoutTabs';
import { authService } from '../../configCommon/service-config';
import NotFound from '../NotFound';
import { useDispatch } from 'react-redux';
import User from '../../model/User';
import { authActions } from '../../redux/slices/userSlice';
const HOST = process.env.REACT_APP_CONFIGURE_HOST;
const PORT = process.env.REACT_APP_CONFIGURE_BE_PORT;
const SECURE_VAR_CONFIG = process.env.REACT_APP_SECURE;

const BasicList: React.FC = () => {
  const [eventId, setEventId] = useState<string>('');
  const curEevent = useSelectorEvent();
  const curEvId = curEevent?._id;
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState<{ root: string, controller?: string }>(rootPath(location.pathname));
  const [userId, setUserId] = useState('');
  const dispatch = useDispatch();
 

  function rootPath(item: string) {
    const pathes = item.split('/')
    const root = (pathes[1]) ?? "/"
    const controller = (pathes[2]) ?? "/"
    return { root: root, controller: controller }
  }

  function handleEventsRootPage(id: any) {
    if (selectedItem?.controller === "new") {
      return <NewEventHeader redirectToDetailsEvent={() => handleCreatedEventDetails(id)} />
    } if (selectedItem?.controller === "details") {
      return <EventDetailHeader />
    } else {
      return <EventsHeader onClickFn={handleCreateClick} />
    }
  }

  const handleClick = (item: string) => {
    setSelectedItem(rootPath(item));
    navigate(item);
  };

  const handleCreateClick = () => {
    setSelectedItem(rootPath("/events/new"))
    navigate('/events/new');
  };
  const handleCreatedEventDetails = (id: any) => {
    if (id) {
      setTimeout(() => {
      setSelectedItem(rootPath('/events/details'));
       navigate(`/events/details/${id}`);
       }, 500)
     }
  }
 
  const logoutFn = async () => {
  authService.logout();
  dispatch(authActions.reset());
}


useEffect(() => {
  const fetchData = async () => {
    try {
      // const responseCheck = await fetch(`http://${HOST}:${PORT}/auth/check`, {
      //   method: 'GET',
      //   credentials: 'include',  
      // });

      // if (!responseCheck.ok) {
      //   throw new Error(`HTTP error! status: ${responseCheck.status}`);
        
      // }

     // const data = await responseCheck.json();  
     const data = await authService.checkAuth();
      if (data.authenticated === true) {
        setUserId(data.user_id);
        const user: User = await authService.getUserData(data.user_id);
        if (user) {
          dispatch(authActions.set(user));
        }
      }
} catch (error) {
      console.error("Error fetching data:", error); 
    }
  };

  fetchData(); 
}, []);

  useEffect(() => {
    if (curEvId) {
      handleCreatedEventDetails(curEvId);
    }
  }, [curEvId]);
  return (
    <Box sx={mainContainerStyle}>
      {(selectedItem.root === "events" || selectedItem.root === "contacts" || selectedItem.root === "scheduler") && (
        <Box sx={subMainContainerStyle}>
          <Box sx={leftSideBarMenuStyle}>
            <Box style={logoContainerSideBarMenuStyle}>
              <Box style={logoSideBarMenuStyle}>
                <img src={logo} alt="logo" style={{ marginRight: '11px' }} />
                {/* <Typography sx={logoStyle}>marasim</Typography> */}
              </Box>
            </Box>
            <List sx={itemsContainerSideBarMenuStyle}>
              <ListItem disablePadding>
                <ListItemButton onClick={() => handleClick("/events/upcoming")} sx={borderRadiusClick}>
                  <ListItemIcon>
                    <CalendarTodayRounded sx={iconStyle} />
                  </ListItemIcon>
                  <ListItemText primary={t('sidebar.button.title.events')} primaryTypographyProps={textStyle} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                
              </ListItem>
              {/* <ListItem disablePadding>
                <ListItemButton onClick={() => handleClick("/contacts/all")} sx={borderRadiusClick}>
                  <ListItemIcon>
                    <ContactsRounded sx={iconStyle} />
                  </ListItemIcon>
                  <ListItemText primary={t('sidebar.button.title.contacts')} primaryTypographyProps={textStyle} />
                </ListItemButton>
              </ListItem> */}
              <ListItemButton onClick={() => handleClick("/scheduler")} sx={borderRadiusClick}>
                  <ListItemIcon>
                    <CalendarMonthRounded sx={iconStyle} />
                  </ListItemIcon>
                  <ListItemText primary={t('sidebar.button.title.scheduler')} primaryTypographyProps={textStyle} />
                </ListItemButton>
            </List>
          </Box>
          
          <List sx={itemsContainerSideBarMenuStyle}>
            <ListItemButton onClick={logoutFn} sx={{ ...borderRadiusClick }}>
              <ListItemIcon>
                <LogoutRounded sx={iconStyle} />
              </ListItemIcon>
              <ListItemText primary={t('sidebar.button.title.logOut')} primaryTypographyProps={textStyle} />
            </ListItemButton>
          </List>
        </Box>
      )}
  
      <Divider orientation='vertical' />
  
      <Box sx={mainContentBoxStyle}>
        {selectedItem.root === "events" && handleEventsRootPage(eventId)}
        {/* {selectedItem.root === "contacts" && <GoogleAutocompleteWithMap/>}  */}
        {selectedItem.root === "scheduler" && <HeaderWithoutTabs onClickFn={handleCreateClick} />}
        {selectedItem.root !== "events" && selectedItem.root !== "contacts" && selectedItem.root !== "scheduler" && <NotFound />}
      </Box>
    </Box>)}


const mainContentBoxStyle = {
  width: '100%'
}

const mainContainerStyle = {
  display: 'flex',
  flexDirection: 'row',
  height: '100vh'
}

const subMainContainerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
}

const leftSideBarMenuStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column'
}

const logoContainerSideBarMenuStyle = {
  marginLeft: 24,
  marginRight: 24,
  marginTop: 8,
}

const logoSideBarMenuStyle = {
  display: 'flex', alignItems: 'center'
}

const itemsContainerSideBarMenuStyle = {
  paddingLeft: 1.5,
  paddingRight: 1.5,
  alignItems: 'center'
}

const textStyle = {
  color: '#181B68',
  fontSize: 20,
  fontFamily: 'Zain',
  fontWeight: '400',
  letterSpacing: 0.15,
  marginLeft: '-25px',
}

const iconStyle = {
  width: 20,
  height: 24,
  color: '#181B68'
}

const logoStyle = {
  color: '#181B68',
  fontSize: 43,
  fontFamily: 'Zain',
  fontWeight: '700',
  letterSpacing: 0.19
}

const borderRadiusClick = {
  borderRadius: '12px'
}

export default BasicList;