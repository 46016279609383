import { Box, Button, Card } from "@mui/material";
import FormsHeaderName from "../../../../components/Forms/FormsHeaderName";
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

type Props = {
    onClickDeleteChanges(): void;
    onClickStayHere(event: any): void;
    onClose(event: any): void;
}
const WarningWindowUnsaved: React.FC<Props> = ({ onClickDeleteChanges, onClickStayHere, onClose }) => {
    const { t } = useTranslation();
    return <Box>
        <Card sx={{ width: '490px', height: '217px', padding: '24px', borderRadius: "16px" }}>
            <Box mt='-20px' ml='11px'>
                <Box display='flex' flexDirection='row' mb='-20px'>
                    <FormsHeaderName sx={{ fontSize: '40px' }}
                     headerName={t('warnmodal.headername.unsaved')}
                     />
                    <Button onClick={onClose} disableRipple sx={{
                        ml: '45px',
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                        },
                    }}
                        startIcon={<CloseIcon sx={{ color: 'rgba(0, 0, 0, 0.56)' }} fontSize="large" />}>
                    </Button>
                </Box>
                <FormsHeaderName sx={{ fontSize: '20px' }}
                headerName={t('warnmodal.warn.prompt')}
                />

                <Box display='flex' flexDirection='row' mt={4} ml={-1} mr={4} gap='10px'>
                    <Button size="small" variant="contained"
                        sx={{
                            ...styleButtonGroup,
                            backgroundColor: 'white',
                            color: '#1E2282',
                            border: '1px solid rgba(30, 34, 130, 0.50)',
                            fontSize: 14.7
                        }}
                        onClick={onClickDeleteChanges}
                    >
                        {t('warnmodal.button.name.backdelete')}
                     </Button>
                    <Button size="small" variant="contained"
                        sx={{
                            ...styleButtonGroup,
                            backgroundColor: '#1E2282',
                            color: 'white',
                        }}
                        onClick={onClickStayHere}
                    >
                        {t('warnmodal.button.name.stayhere')}
                    </Button>
                </Box>
            </Box >
        </Card>
    </Box>
}

const styleButtonGroup = {
    fontFamily: 'Zain',
    fontSize: 20,
    height: '4vh',
    ml: '12px',
    borderRadius: '8px',
    textTransform: 'uppercase',
    letterSpacing: 0.46,
    wordWrap: 'break-word',
    flex: 1
}


export default WarningWindowUnsaved;