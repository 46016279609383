import { CalendarMonthRounded, ListRounded } from "@mui/icons-material";
import { Box, Button, ToggleButton, ToggleButtonGroup } from "@mui/material"
import { useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  buttonName: string;
  onClickFn(): void;
  disabled: boolean;
}

const buttonCreateEventStyle = {
  fontFamily: 'Zain',
  fontSize: 20,
  background: '#1E2282',
  height: '42px',
  ml: '12px',
  borderRadius: '8px',
  textTransform: 'uppercase',
  letterSpacing: 0.46,
  wordWrap: 'break-word',
  paddingLeft: '22px', paddingRight: '22px', paddingTop: '8px', paddingBottom: '8px',

  '@media (max-width: 800px)': {
    fontSize: '8px',
    height: '25px',
    padding: '6px 12px',
  }
}

const HeaderEventsListButton: React.FC<Props> = (props) => {
  const [alignment, setAlignment] = useState<string | null>("left");
  const [isActiveEv, setIsActiveEv] = useState(false);
  const [isActiveCal, setIsActiveCal] = useState(false);
  const location = useLocation();
 
const handleAlignment = (
  event: React.MouseEvent<HTMLElement>,
  newAlignment: string | null
) => {
  setAlignment(newAlignment);
};


useEffect(() => {
  if (location.pathname === '/events') {
    setIsActiveEv(true);
    setIsActiveCal(false);
  } else if (location.pathname === '/scheduler') {
    setIsActiveEv(false);
    setIsActiveCal(true);
  }
}, [location.pathname]);




const toEvents = () => {
  const path = window.location.href.split('/').pop();
  if (path == 'events') return;
  window.location.href = '/events'

}

  const toCalendar = () => {
  const path = window.location.href.split('/').pop();
  if (path == 'scheduler') return;
  window.location.href = '/scheduler';
  }

return <Box display='flex' flexDirection='row' mr={4}>

<ToggleButtonGroup sx={{
    '@media (max-width: 800px)': {
    fontSize: '10px',
    height: '3.5vh' }}}
    value={alignment}
    exclusive
    onChange={handleAlignment}
    aria-label="text alignment"
  >
    
    <ToggleButton size="small" value="left" aria-label="left aligned"
     onClick={toEvents}
     selected={isActiveEv}
    >
    <ListRounded />
    </ToggleButton>
    
    <ToggleButton size="small"  value="center" aria-label="centered"
    onClick={toCalendar}
    selected={isActiveCal}
    >
    <CalendarMonthRounded />
    </ToggleButton>
  </ToggleButtonGroup>
    
    <Button startIcon={<AddIcon />} disabled={props.disabled} form='form-create-ev' type="submit" onClick={props.onClickFn} size="small" variant="contained" sx={buttonCreateEventStyle}>
      {props.buttonName}
    </Button>

  </Box>
}

export default HeaderEventsListButton