import styles from '../CSS/SignIn/Container.module.css';
import SignInSectionHeader from "./SignIn/SignInComponents/SignInSectionHeader";
import { useTranslation } from 'react-i18next';

const NotFound: React.FC = () => {
const { t } = useTranslation();
    return (<div className={styles.container}>
      <div className={styles.form}>
        <div className={styles.libraryLogoPlaceholder} />
        <div className={styles.spacingVertical}>
          <div className={styles.minHeight} />
        </div>
        <SignInSectionHeader title={t('notfound.page.title')} />
        <div className={styles.spacingVertical}>

          <div className={styles.minHeight} />
        </div>
      </div>

    </div>);
}

export default NotFound