import { Button } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';

type Props = {
    butName: string;
    disabled: boolean;
    handleClickFn:() => void;
    }


const SaveDraftButton: React.FC<Props> = (props) => {
  
    const style = {
            color: '#1E2282',
            fontFamily: 'Zain',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 400, 
            letterSpacing: '0.4px',
            textTransform: 'uppercase',
            bordeRradius: 'var(--borderRadius, 8px)',
            borderRadius: 'var(--borderRadius, 8px)',
            mt: '24px',
            ml: '16px',
            width: '70px',
            height: '40px',
            
            '& .MuiButton-endIcon': {
              marginLeft: '-1px',
            },
          };
        
        
          return <Button
          disabled={props.disabled}
          onClick={props.handleClickFn}
          sx={style}
          startIcon={<SaveIcon/>}>{props.butName}
          </Button>
    
    }


export default SaveDraftButton;