// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * @author Nick M.
 * @create date 2024-09-09
 * @desc Base styles for the component
 */

.AddNewContact_modalTopTitle__HfHeq {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    /* height: 91px; */
    flex-shrink: 0; /* Prevent shrinking */
}

.AddNewContact_modalTopCloseBtn__-zZya {
    padding-bottom: 8px;
}

.AddNewContact_modalHorizontalContainer__d\\+B0f {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    margin-top: 8px;
}

.AddNewContact_modalVerticalContainer__0E\\+qi {
    display: flex;
    /* align-items: flex-start; */
    /* gap: 12px; */
    /* align-self: stretch; */
    /* margin-top: 16px; */
    /* flex-shrink: 0; */
    flex-direction: column;
}

.AddNewContact_modalHorizontalBottomContainer__VFHPY {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    padding-top: 24px;
    flex-shrink: 0;
}

::-webkit-scrollbar-track {
    background: gainsboro;
    border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Contacts/AddNewContact/index.module.css"],"names":[],"mappings":"AAAA;;;;EAIE;;AAEF;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;IAClB,cAAc,EAAE,sBAAsB;AAC1C;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,SAAS;IACT,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,eAAe;IACf,yBAAyB;IACzB,sBAAsB;IACtB,oBAAoB;IACpB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,SAAS;IACT,mBAAmB;IACnB,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,qBAAqB;IACrB,kBAAkB;AACtB","sourcesContent":["/**\n * @author Nick M.\n * @create date 2024-09-09\n * @desc Base styles for the component\n */\n\n.modalTopTitle {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    align-self: stretch;\n    /* height: 91px; */\n    flex-shrink: 0; /* Prevent shrinking */\n}\n\n.modalTopCloseBtn {\n    padding-bottom: 8px;\n}\n\n.modalHorizontalContainer {\n    display: flex;\n    align-items: flex-start;\n    gap: 12px;\n    align-self: stretch;\n    margin-top: 8px;\n}\n\n.modalVerticalContainer {\n    display: flex;\n    /* align-items: flex-start; */\n    /* gap: 12px; */\n    /* align-self: stretch; */\n    /* margin-top: 16px; */\n    /* flex-shrink: 0; */\n    flex-direction: column;\n}\n\n.modalHorizontalBottomContainer {\n    display: flex;\n    align-items: flex-start;\n    gap: 10px;\n    align-self: stretch;\n    padding-top: 24px;\n    flex-shrink: 0;\n}\n\n::-webkit-scrollbar-track {\n    background: gainsboro;\n    border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalTopTitle": `AddNewContact_modalTopTitle__HfHeq`,
	"modalTopCloseBtn": `AddNewContact_modalTopCloseBtn__-zZya`,
	"modalHorizontalContainer": `AddNewContact_modalHorizontalContainer__d+B0f`,
	"modalVerticalContainer": `AddNewContact_modalVerticalContainer__0E+qi`,
	"modalHorizontalBottomContainer": `AddNewContact_modalHorizontalBottomContainer__VFHPY`
};
export default ___CSS_LOADER_EXPORT___;
