import { fetchGetData, fetchPostData, fetchPutData } from "../../utils/restFuncs/fetch";
import ContactsGroupML from "../../model/ContactsGroups/ContactsGroupML";
import NetworkServiceInterface from "./NetworkServiceInterface";
import ContactPrefixML from "../../model/Contact/ContactPrefixML";
import ContactML from "../../model/Contact/ContactML";

const SERVER_NOT_AVAILABLE = 'Server is unavailable, repeat later on';

export default class NetworkServiceWorker implements NetworkServiceInterface {
    url: string;
    constructor(secure: string, host: string) {
        this.url = `${secure}://${host}`;
    }

    async getAllContacts(withGroups?: boolean): Promise<ContactML[]> {
        return fetchGetData<ContactML[]>(this.url, SERVER_NOT_AVAILABLE, `contacts/getAll?groups=${encodeURIComponent(withGroups ?? false)}`);
    }

    async getAllContactsGroups(withContacts?: boolean): Promise<ContactsGroupML[]> {
        return fetchGetData<ContactsGroupML[]>(this.url, SERVER_NOT_AVAILABLE, `contactsGroup/getAll?contacts=${encodeURIComponent(withContacts ?? false)}`);
    }

    async getAllContactsPrefixs(): Promise<ContactPrefixML[]> {
        return fetchGetData<ContactPrefixML[]>(this.url, SERVER_NOT_AVAILABLE, 'contactsPrefix/getAll');
    }

    async postAddNewContact(body: any): Promise<ContactML | undefined> {
        return fetchPostData<ContactML>(this.url, body, SERVER_NOT_AVAILABLE, 'contacts/add');
    }

    async putContact(contactId: string, body: any): Promise<ContactML | undefined> {
        return fetchPutData<ContactML>(this.url, body, SERVER_NOT_AVAILABLE, `contacts/update/${contactId}`);
    }

    async postAddNewContactsGroup(body: any): Promise<ContactsGroupML | undefined> {
        return fetchPostData<ContactsGroupML>(this.url, body, SERVER_NOT_AVAILABLE, 'contactsGroup/add');
    }

    async putContactsGroup(groupId: string, body: any): Promise<ContactsGroupML | undefined> {
        return fetchPutData<ContactsGroupML>(this.url, body, SERVER_NOT_AVAILABLE, `contactsGroup/update/${groupId}`);
    }




    //     //Groups:
    //     getAllGroups(): Promise<Group[]> {
    //         return fetchGetData<Group[]>(this.url, SERVER_NOT_AVAILABLE, 'groups/all');
    //     }
    //     async getAllGuestGroups(): Promise<any> {
    //         return fetchGetData<any>(this.url, SERVER_NOT_AVAILABLE, 'groups/guests');
    //     }


    //     //Guests:
    //     getAllGuests(): Promise<Guest[]> {
    //         return fetchGetData<Guest[]>(this.url, SERVER_NOT_AVAILABLE, 'guests/all');
    //     }
    //     async getAllGuestStatuses(): Promise<any> {
    //         return fetchGetData<any>(this.url, SERVER_NOT_AVAILABLE, 'invitations/checkstatus');
    //     }


    //     //Events
    //     async sendMessage(payload: any): Promise<any> {
    //         try {
    //             const response = await fetch(`${this.url}/invitations/send`, {
    //                 method: 'POST',
    //                 headers: {'Content-Type': 'application/json'},
    //                 body: JSON.stringify(payload),
    //             });
    //             if (response.ok) {
    //                 return await response.json();
    //             } else {
    //                 throw new Error('Failed to fetch: ' + response.statusText);
    //             }
    //         } catch (error) {
    //             console.error(SERVER_NOT_AVAILABLE, error);
    //             throw SERVER_NOT_AVAILABLE;
    //         }
    //     }
    //     async getAllEventsWithStatuses (): Promise<Event[]> {
    //         return fetchGetData<any>(this.url, SERVER_NOT_AVAILABLE, 'invitations/all');
    //     }



}
