import { Typography } from "@mui/material";

type Props = {
    headerName: string;
}

const headerNameStyle = {
    fontFamily: 'Zain',
    fontSize: 48,
    color: 'rgba(0,0,0,0.87)',
    fontWeight: 400,
    lineHeight: '52.80px',
    wordWrap: 'break-word',
    ml: '34px',
    mt: '14px',
    mb: '8px',
}


const HeaderName: React.FC<Props> = (props) => {
    return <Typography sx={headerNameStyle}>{props.headerName}</Typography>
}

export default HeaderName;