import { Box, Modal } from "@mui/material";
import ReturnButton from "../components/HeaderListsCommonComps/ReturnButtonIcon";
import { useLocation } from "react-router-dom";
import HeaderEventsListButton from "../components/HeaderListsCommonComps/HeaderEventsListButtons";
import { useState } from "react";
import { schedulerTabsConfig } from "../configCommon/tabs-config";
import TopHeaderWithoutTabs from "../components/tabs/TopHeaderWithoutTabs";
import Evnt from "../model/Event/Evnt";
import { useTranslation } from 'react-i18next';

type Props = {
    onClickFn(): void;
}

const HeaderWithoutTabs: React.FC<Props> = (props) => {

    const { t } = useTranslation();
    return (
    <>
        <TopHeaderWithoutTabs
            pageName={t('events.header.name.eventslist')} 
            pageTopButtons={<HeaderEventsListButton disabled={false} buttonName={t('events.header.button.newevent')} onClickFn={props.onClickFn} />}
            components={schedulerTabsConfig.components}
        />
    </>
    );

}

const styleModal = {
    position: 'absolute' as 'absolute',
    top: '35%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '16px',
    padding: '0px',
};

export default HeaderWithoutTabs;

