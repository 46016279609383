import * as React from 'react';
import { Box, CircularProgress, Modal, TextField } from '@mui/material';
import Scheduler, { EventML, StartWeek, TransitionMode, Mode } from 'events-scheduler';

import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { useTranslation } from 'react-i18next';
import { eventsService } from '../../configCommon/service-config';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelectorAuth, useSelectorEvent } from '../../redux/store';
import Evnt from '../../model/Event/Evnt';
import { useDispatch } from 'react-redux';
import { eventActions } from '../../redux/slices/eventSlice';
import EventDetailModalView from './EventDetailModalView';




export default function SchedulerMain() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [evs, setEvents] = useState<any[]>();
    const [hosts, setHosts] = useState<any[]>();
    const curUser = useSelectorAuth();
    const [openLocationModal, setOpenLocationModal] = useState(false);
    const [evId, setEvId] = useState<string>();
    const [e, setE] = useState<Evnt>();
    const dispatch = useDispatch();
    const curEv = useSelectorEvent();
    

    useEffect(() => {
        const fetchData = async () => {
          try {
            const allEvents = await eventsService.getAllEvents() as any;
            if (allEvents.message) {
                navigate('/');
                return;
              }
            const guests = await eventsService.getAllMainGuests() as any;
            console.log(allEvents);
            
           setEvents(allEvents);
           setHosts(guests);
        } catch (error) {
            console.error(error); 
          } 
          finally {
            setLoading(false);
          }
        };
        
        fetchData();
      }, [navigate]);

    const [state] = React.useState({
        options: {
            transitionMode: TransitionMode.ZOOM,
            startWeekOn: StartWeek.MON,
            defaultMode: Mode.WEEK,
            minWidth: 340,
            maxWidth: 1540,
            minHeight: 200,
            maxHeight: 9999,
            reverseTimelineOrder: false,
        },
        alertProps: {
            open: true,
            color: "info",          // info | success | warning | error
            severity: "info",       // info | success | warning | error
            message: "🚀 Let's start with awesome react-mui-scheduler 🔥 🔥 🔥",
            showActionButton: true,
            showNotification: true,
            delay: 1500,
        },
        toolbarProps: {
            showSearchBar: true,
            showSwitchModeButtons: {
                showMonthButton: true,
                showWeekButton: true,
                showDayButton: true,
                showTimelineButton: true,
            },
            showDatePicker: true,
            showTodayAction: true,
            showOptions: true,
            optionMenus: [
                // {
                //     label: "Read events",
                //     icon: <PlayCircleOutlineIcon fontSize="small" />,
                // },
                // {
                //     label: "Refresh",
                //     icon: <AutorenewIcon fontSize="small" />,
                // },
                // {
                //     label: "Export",
                //     icon: <ArchiveIcon fontSize="small" />,
                // },
                {
                    label: t('page.print.label'),
                    icon: <LocalPrintshopIcon fontSize="small" />,
                },
            ],
        },
    });

    const formatDate = (dateString: string): Date => {
        return new Date(dateString);
      };


     const getEndDate = (dateAndTimeStart:any, dateAndTimeEnd:any) => {
        const st = formatDate(dateAndTimeStart);
        const end = formatDate(dateAndTimeEnd);
        st.setDate(st.getDate() + 1); 
        st.setHours(0, 0, 0, 0); 

  if (end > st) {
    return st;
  }
  return end;
};

     
    const mapEvents = (realEvents: any, guests: any) => {   
      const r = realEvents
      .filter((event: any) => {
        if (curUser?.pddUser == '0') {
          return guests[1]?._id === event.mainGuest;
        }
        return true; 
      })
      .map((event: any) => {
        const guest = guests.find((g: any) => g._id === event.mainGuest);
        return {
          id: event._id,
          label: event.nameOfEvent,
          groupLabel: event.mainGuest,
          user: event.mainGuest,
          color: guest ? guest.color : "#FFFFFF",
          startDate: formatDate(event.dateAndTimeStart),
          endDate: getEndDate(event.dateAndTimeStart, event.dateAndTimeEnd),
          createdAt: new Date(),
          createdBy: curUser?._id,
        };
      });
        console.log(r);
        return r;
        
      };

      

    const handleCellClick = (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>, row: any, day: any) => {
        // Do something...
    };

    const handleEventClick = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>, task: EventML) => {
       console.log(task);
       
        const id = task.id as string;
        setEvId(id);

       const res = await eventsService.getEventById(id); 
       const mainGuest = hosts!.find(h => h._id === res.mainGuest);
       console.log(mainGuest.nameEn);

       const evDetails: Evnt = {
        ...res, mainGuest: mainGuest.nameEn
       }

      

       setE(evDetails);

      
       

       
       console.log('event: ',res);
       
       setOpenLocationModal(true)
    };

    const handleEventsChange = (item: EventML) => {
        // Do something...
    };

    const handleAlertCloseButtonClicked = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // Do something...
    };

    const handleDateChange = (day: number, date: number | Date | null) => {
        // Do something...
    };

    const onCloseModalFn = () => {
        setOpenLocationModal(false);
    }


    if (loading) {
        return (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress /> {/* Spinner from Material UI */}
          </div>
        );
      }

    return (
            <>

<Modal
            
            open={openLocationModal}
            onClose={(event, reason) => {
            if (reason !== "backdropClick") {
            setOpenLocationModal(false);
            }
}}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styleModal}>
              {openLocationModal &&
            <EventDetailModalView
            curEevent={e!}
            onCloseModal={onCloseModalFn}/>}
               
               
            </Box>
          </Modal>

        <Scheduler
            locale="ar"
            events={mapEvents(evs, hosts)}
            legacyStyle={false}
            options={state?.options}
            //alertProps={state?.alertProps}
            toolbarProps={state?.toolbarProps}
            onEventsChange={handleEventsChange}
            onCellClick={handleCellClick}
            onTaskClick={handleEventClick}
            onAlertCloseButtonClicked={handleAlertCloseButtonClicked}
            onDateChange={handleDateChange}
        />
        
        </>
    );
}

const styleModal = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '480px',
    height: '700px',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '16px',
    padding: '24px',
  };

const mainContainer = {
    // position: 'relative', height: '100vh', border: '32px solid #FAFAFA'


    // flexGrow: 1, overflowY: 'auto', backgroundColor: '#FAFAFA'
    // height: '100vh',
    // position: 'relative', height: '100vh', border: '32px solid #FAFAFA'
    // flexGrow: 1, overflowY: 'auto', backgroundColor: '#FAFAFA'

    // display: 'flex',
    // position: 'relative',
    // flexDirection: 'column',
    // height: '100vh',
    // border: '32px solid #FAFAFA'
}

