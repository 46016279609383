import styles from '../../../CSS/SignIn/Button.module.css';
import { Button } from '@mui/material';


type Props = {
	uaePassRedirectFn(): void;
	logo?: any;
  name?: string
}

const ButtonUae: React.FC<Props> = (props) => {
  	return (
        <Button
  onClick={props.uaePassRedirectFn}
  sx={{
    backgroundColor: 'white',
    color: 'transparent',
    '&:hover': {
      backgroundColor: 'white', 
      boxShadow: 'none', 
    },
  }}
>
  <img src={props.logo} alt="UAE Pass Logo" />
</Button>

    );
};

export default ButtonUae;
