import React, { useEffect } from 'react';
import { Box, Tabs, Tab, Divider } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import HeaderName from '../HeaderListsCommonComps/HeaderName';
import { useTranslation } from 'react-i18next';

export default function ContactsEventsReuseTabs({ pageName, pageTopButtons, basePath, tabNames, labels, components, returnButton }: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = React.useState(0);
  const { t } = useTranslation();
  {t('tranlsationKey')}
  const translatedLabels = labels.map((label: string) => t(label));

  const currentPath = location.pathname.split('/').pop()

  const tabMapping = tabNames.reduce((acc: any, name: any, index: any) => {
    acc[name] = index;
    return acc;
  }, {} as { [key: string]: number });

  useEffect(() => {
    setSelectedTab(tabMapping[currentPath!] || 0);
  }, [currentPath, tabMapping]);



  const handleTabChange = (event: any, newValue: any) => {
    setSelectedTab(newValue);
    navigate(`/${basePath}/${tabNames[newValue]}`);
  };

  return (<Box sx={mainBoxStyle}>
    <Box sx={subMainBoxStyle}>
      <Box sx={mainHeaderStyle}>

        <Box sx={buttonHeaderContainer}>
          {returnButton}
          <Box sx={spaceBetwButtonHeader}>
            <HeaderName headerName={pageName} />
          </Box>
        </Box>
        {pageTopButtons}
      </Box>
      <Box sx={subHeaderContainerStyle}>
        <Tabs TabIndicatorProps={{ style: { backgroundColor: '#1E2282', height: 2 } }}
          value={selectedTab}
          onChange={handleTabChange}
        >
          {translatedLabels.map((label: any, index: any) => (
            <Tab
              key={index}
              label={label}
              sx={[subHeaderTabStyle, { marginLeft: index === 0 ? '32px' : '0' }]}
              style={{ color: selectedTab === index ? '#1E2282' : 'rgba(0, 0, 0, 0.60)' }}
            />
          ))}
        </Tabs>
      </Box>
    </Box>
    <Divider />
    {components[selectedTab]}
    <Box sx={bottomContainerStyle}></Box>
  </Box >
  );
}

const buttonHeaderContainer = {
  flexDirection: 'row', display: 'inline-flex'
}

const spaceBetwButtonHeader = {
  ml: '-12px'
}

const mainBoxStyle = {
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '100vh'
}

const subMainBoxStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'column',
  alignSelf: 'stretch',
  flexShrink: 0
}

const mainHeaderStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
}

const subHeaderContainerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%'
}

const subHeaderTabStyle = {
  fontFamily: 'Zain',
  fontSize: 18,
  paddingTop: -1,
}

const bottomContainerStyle = {
  display: 'flex',
  alignSelf: 'stretch',
  flexShrink: 0
}