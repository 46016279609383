import { configureStore } from "@reduxjs/toolkit";
import { eventReducer } from "./slices/eventSlice";
import { useSelector } from "react-redux";
import Evnt from "../model/Event/Evnt";
import { authReducer } from "./slices/userSlice";
import User from "../model/User";

export const store = configureStore({
    reducer: {
        eventState: eventReducer,
        authState: authReducer,
    }
});

export function useSelectorEvent() {
    return useSelector<any, Evnt>(state => state.eventState.evnt)
}

export function useSelectorAuth() {
    return useSelector<any, User>(state => state.authState.user);
}