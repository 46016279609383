import { useNavigate } from 'react-router-dom';
import styles from '../../CSS/SignIn/Container.module.css';
import ButtonUaePass from './SignInComponents/ButtonUaePass';
import SignInSectionHeader from './SignInComponents/SignInSectionHeader';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UaePassLogo from '../../assets/Logos/AR_UAEPASS_Sign_in_Btn_Outline_Default_White_Pressed.svg';
import LogoSignIn from '../../assets/Logos/PDDLogoSignWhite.svg'
import { Box, Typography } from '@mui/material';
import axios from 'axios';
const acessTokenServerEndpoint = process.env.REACT_APP_ACCESS_TOKEN_ENDPOINT!;
const callBackServerEndpoint = process.env.REACT_APP_CALLBACK_ENDPOINT!;

const SignInForm: React.FC = () => {

const { t } = useTranslation();

  function uaePassRedirectFn() {

   //let path = 'https://stg-id.uaepass.ae/idshub/authorize?client_id=marasim_web_stage&response_type=code&scope=urn:uae:digitalid:profile:general urn:uae:digitalid:profile:general:profileType urn:uae:digitalid:profile:general:unifiedId&acr_values=urn:safelayer:tws:policies:authentication:level:low&redirect_uri=https://marasimqa.dubai.gov.ae/auth/callback'

     let path = acessTokenServerEndpoint;
     window.location.href = path;
     //const urlParams = new URLSearchParams(window.location.search);
     //console.log(urlParams);
     
     //const code = urlParams.get('code');
     //console.log(code);
 
    //"https://marasimqa.dubai.gov.ae/?code=07d70f16-7ca9-3d10-9366-318bd53b33c4"
 
    // setIsRedirected(true);
   }


    // useEffect(() => {
    //   // Extract the code from the URL
    //   const urlParams = new URLSearchParams(window.location.search);
    //   console.log(urlParams);
      
    //   const code = urlParams.get('code');
    //   console.log(code);
      
  
    //   if (code) {
    //     // Send the code to the backend
    //     axios.get(`http://localhost:3501/auth/callback?code=${code}`)
    //       .then(response => {
    //         console.log('Code sent successfully', response.data);
    //         // Handle successful response (e.g., store tokens or navigate)
    //       })
    //       .catch(error => {
    //         console.error('Error sending code:', error);
    //       });
    //   }
    // }, []);
  
    // return <div>Processing authentication...</div>;
  

   
  const navigate = useNavigate();
  const [isRedirected, setIsRedirected] = useState(false);


  // useEffect(() => {
  //   if (isRedirected) {
  //     const fetchData = async () => {
  //       try {
  //         // 1. Fetch data from the callback server endpoint to get the initial data
  //         const responseCallback = await fetch(callBackServerEndpoint, {
  //           method: 'GET',
  //           credentials: 'include',  // Ensure cookies (with token) are included
  //         });
  
  //         if (responseCallback.ok) {
  //           const result = await responseCallback.json();
  //           console.log('Callback data received:', result);
  
  //           // 2. Now, make a request to the /check endpoint to verify the token
  //           const responseCheck = await fetch('/check', {
  //             method: 'GET',
  //             credentials: 'include',  // Again, ensure cookies are sent
  //           });
  
  //           if (responseCheck.ok) {
  //             const checkData = await responseCheck.json();
  
  //             console.log('JWT verification result:', checkData);
  
  //             if (checkData.authenticated) {
  //               // User is authenticated, proceed to protected route
  //               navigate('/events', { replace: true });
  //             } else {
  //               // If not authenticated, navigate to login or show an error
  //               console.error('User is not authenticated:', checkData.message);
  //               navigate('/');  // Redirect to login page
  //             }
  //           } else {
  //             console.error('Failed to check JWT token');
  //             navigate('/');  // Redirect to login page
  //           }
  //         } else {
  //           console.error('Failed to fetch data from callback endpoint');
  //         }
  //       } catch (error) {
  //         console.error('Fetch error:', error);
  //         navigate('/');  // Redirect to login page in case of error
  //       }
  //     };
  
  //     // Optional spinner delay for better UX (optional)
  //     setTimeout(() => {
  //       fetchData();
  //     }, 1000);  // Adjust this delay if you want a spinner for a bit longer
  //   }
  // }, [isRedirected, navigate]);
  


  // useEffect(() => {
  //   if (isRedirected) {
  //   const fetchData = async () => {
  //       try {
  //         const response = await fetch(callBackServerEndpoint, {
  //           method: 'GET',
  //           credentials: 'include',
  //         });

  //         if (response.ok) {
  //           const data = await response.json();

  //           console.log('data' + Object.values(data));

  //           console.log('Protected resource:', data);
  //           navigate('/events', { replace: true });
  //         } else {
  //           console.error('Failed to fetch protected resource');
  //         }
  //       } catch (error) {
  //         console.error('Fetch error:', error);
  //       }
  //     };

  //     fetchData();
  //   }
  // }, [isRedirected, navigate]);


  return (
    <div className={styles.pageContainer}>
        <div className={styles.leftBlock}>
        <img src={LogoSignIn} alt="Logo" className={styles.logoSignIn}/>
        <Box mt='350px'>
        <Typography sx={{...welcomePageTitle, fontSize: 18,fontWeight: '400',letterSpacing: 0.10}}>
        دائرة التشريفات والضيافة - دبي
        </Typography>
        <Typography sx={{...welcomePageTitle, fontSize: 18,fontWeight: '400',letterSpacing: 0.10}}>
        Protocol Department — Dubai
        </Typography>
        <Typography sx={{...welcomePageTitle, fontSize: 70, fontWeight: '700',  lineHeight: 1, mt:'20px'}}>
         {t('signin.page.welcome.to.marasim')}
        </Typography>
        </Box>
     
      </div>
      <div className={styles.container}>
        <Box ml='500px' mt='300px'>
        <div className={styles.form}>
          <div className={styles.libraryLogoPlaceholder} />
          <div className={styles.spacingVertical}>
            <div className={styles.minHeight} />
          </div>
          <SignInSectionHeader title={t('signin.page.title')} />
          <div className={styles.spacingVertical}>
            <div className={styles.minHeight} />
          </div>
          <div className={styles.button1}>
            <div className={styles.base}>
              <ButtonUaePass uaePassRedirectFn={uaePassRedirectFn} logo={UaePassLogo} />
            </div>
          </div>
        </div>
        </Box>
      </div>
    </div>
  );



  



  // return (
  //   <div className={styles.container}>
  //     <div className={styles.form}>
  //       <div className={styles.libraryLogoPlaceholder} />
  //       <div className={styles.spacingVertical}>
  //         <div className={styles.minHeight} />
  //       </div>
  //       <SignInSectionHeader title={t('signin.page.title')} />
  //       <div className={styles.spacingVertical}>

  //         <div className={styles.minHeight} />
  //       </div>

  //       <div className={styles.button1}>

  //         <div className={styles.base}>
  //           <ButtonUaePass uaePassRedirectFn={uaePassRedirectFn} logo={UaePassLogo} />
  //         </div>


  //       </div>


  //     </div>

  //   </div>);
};


const welcomePageTitle = {
  color: 'white',
  fontFamily: 'Zain',
  wordWrap: 'break-word'
}


    



export default SignInForm;
