/**
 * @author Nick M.
 * @create date 2024-09-11
 * @desc Contacts lists
 */

import * as React from 'react';
import { GridColDef, GridToolbarContainer, GridSlots } from '@mui/x-data-grid';
import { Box, TextField } from '@mui/material';
import ContactML from '../../../model/Contact/ContactML';
import { NetworkService } from '../../../configCommon/service-config';
import ContactModalView from '../AddNewContact/ContactModalView';
import CustomDataGridType1 from '../../../components/CustomDataGrid/CustomDataGridType1';
import AvatarNameRow from '../../../components/CustomDataGrid/Rows/AvatarNameRow';
import ContactGroupsRow from '../../../components/CustomDataGrid/Rows/ContactGroupsRow';
import ContactContactsRow from '../../../components/CustomDataGrid/Rows/ContactContactsRow';
import SingleEditActionRow from '../../../components/CustomDataGrid/Rows/SingleEditActionRow';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// ------------ Top search tool bar for the data grid ------------
interface SearchToolbarProps {
    // setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    // setRowModesModel: (
    //     newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    // ) => void;
}

type Props = {
    setContactsLength?: React.Dispatch<number>;
}

export default function ContactsList({setContactsLength}: Props) {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const columns: GridColDef[] = [
        AvatarNameRow({ fId: 'fullName', fFlex: 0.335, columnName: t('contacts.table.column.name.full.name'), marginL: 1 }),
        ContactGroupsRow({ fId: 'groups', fFlex: 0.3, columnName: t('contacts.table.column.name.group') }),
        ContactContactsRow({ fId: 'contact', fFlex: 0.230, columnName: t('contacts.table.column.name.contacts') }),
        SingleEditActionRow({ fId: 'actions', fFlex: 0.035 })
    ];

    const [contacts, setContacts] = React.useState<ContactML[] | null>(null);
    const [networkError, setNetworkError] = React.useState<string | unknown>(null);
    const [loading, setLoading] = React.useState<boolean>(true);

    const [open, setOpen] = React.useState<ContactML | undefined>(undefined);
    const handleClose = (contact?: ContactML) => {
        if (contact && contacts) {
            if (contacts.findIndex(c => c._id === contact._id) !== -1) {
                contacts[contacts.findIndex(c => c._id === contact._id)] = contact;
            }
        }
        setOpen(undefined)
    };

    // Handle changes to selected contact groups
    const handleEditContact = (contact: ContactML) => {
        setOpen(contact)
    };

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch both contact groups and prefixes (concurrently)
                const [contacts] = await Promise.all([
                    NetworkService.getAllContacts(true)
                ]) as any;

                if (contacts.message) {
                    navigate('/');
                    return;
                }
                
                // Update state with the fetched data
                setContacts(contacts);
                setContactsLength!(contacts.length);
            } catch (error) {
                setNetworkError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const rows = contacts?.map((contact) => {
        return {
            id: contact._id,
            fullName: { 'avatar': contact.qr_code, 'fullName': contact.full_name },
            groups: contact.groups,
            contact: { 'phone': contact.phone, 'email': contact.email },
            actions: { 'func': handleEditContact, 'model': contact }
        }
    });
    return (
        <Box sx={mainContainer}>
            <CustomDataGridType1
                rows={rows}
                columns={columns}
                slots={{ toolbar: SearchToolbar as GridSlots['toolbar'] }}
            />
            {open && <ContactModalView open={(open !== undefined)} contact={open} handleClose={handleClose} />}
        </Box>
    );


    function SearchToolbar(props: SearchToolbarProps) {
        return (
            <GridToolbarContainer>
                <Box sx={{ padding: 1.5, width: 300 }}>
                    <TextField name='full_name' label={t('contacts.list.search.input.label')} placeholder={t('contacts.list.search.input.plhd')} variant="outlined" fullWidth size="medium" type='text' />
                </Box>
            </GridToolbarContainer>
        );
    }
}

const mainContainer = {
    position: 'relative', height: '100vh', border: '32px solid #FAFAFA'
}