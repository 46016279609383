import styles from '../CSS/SignIn/Container.module.css';
import SignInSectionHeader from "./SignIn/SignInComponents/SignInSectionHeader";
import ButtonUaePass from "./SignIn/SignInComponents/ButtonUaePass";
import { useTranslation } from 'react-i18next';
import UaePassLogo from '../assets/Logos/AR_UAEPASS_Continue with_Btn_Outline_Default_White_Active.svg';
import { useLocation } from 'react-router-dom';
import { Button } from '@mui/material';

const NoAuthorize: React.FC = () => {
 const { t } = useTranslation();

const useQuery = () => {
   return new URLSearchParams(useLocation().search);
 };
 
   const query = useQuery();
   const status = query.get('status') as keyof typeof messages;
   console.log(typeof(status));
   
 
   const messages = {
      //sop1
      'WA01': 'لا يحق لك الوصول إلى هذه الخدمة. إما أن حسابك لم يتم ترقيته أو لديك حساب ضيف. يرجى التواصل مع فريق تطبيق مراسم للوصول إلى الخدمة.',
      //noregistered
     'WA02': ' هذه الخدمة مخصصة للمستخدمين المسجلين فقط، يرجى التواصل مع فريق تطبيق مراسم للحصول على الخدمات.',
     //cancelled the login
     'WA03':  'قام المستخدم بإلغاء تسجيل الدخول',
     //other
     'WA04' : 'حدث خطأ ما أثناء تسجيل الدخول، يرجى المحاولة مرة أخرى لاحقًا!'
   };

   const defaultMessage = messages['WA04'];


const signinRedirectFn = () => {
window.location.href = '/';
}

return (
    <div className={styles.container}>
          <div className={styles.form}>
                <div className={styles.libraryLogoPlaceholder} />
                <div className={styles.spacingVertical}>
                      <div className={styles.minHeight} />
                </div>
                {/* <SignInSectionHeader title={t('notauth.page.sorry')}/> */}
                 <SignInSectionHeader title={messages[status] || defaultMessage}/>
                <div className={styles.spacingVertical}>
                      <div className={styles.minHeight} />
                </div>
                <div className={styles.actionsWrapper}>
                      <div className={styles.button} />
                      <div className={styles.button1}>
                            <div className={styles.base}>
            <Button variant='outlined' 
            onClick={signinRedirectFn}
 
  sx={{
      border: '1px solid black',
      borderRadius: '25px',
      fontFamily: 'Zain',
      fontSize: '25px',
      color: 'black',
  }}
>
  'العودة إلى الصفحة الرئيسية'
</Button>


                                  {/* <ButtonUaePass uaePassRedirectFn={signinRedirectFn} name={'العودة إلى الصفحة الرئيسية'}/> */}
                            </div>
                      </div>
                </div>
                
          </div>
    </div>);
};


export default NoAuthorize