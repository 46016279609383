/**
 * @author Nick M.
 * @create date 2024-09-17
 * @desc [description]
 */

import React from 'react';
import { DataGrid, GridSlots } from '@mui/x-data-grid';

interface ComponentProps {
    rows?: readonly any[],
    columns?: any,
    rowHeight?: number,
    slots?: Partial<GridSlots> | undefined,
    paginationModel?: { page: number, pageSize: number },
    pageSizeOptions?: [number] | undefined
}

const CustomDataGridType1: React.FC<ComponentProps> = ({
    rows, columns, rowHeight = 70, slots,
    paginationModel = { page: 0, pageSize: 10 },
    pageSizeOptions = [5, 10, 15, 20, 25, 30]
}) => {
    return (<>
        <DataGrid
            style={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                backgroundColor: '#fff'
            }}
            rows={rows}
            columns={columns}
            initialState={{ pagination: { paginationModel } }}
            pageSizeOptions={pageSizeOptions}
            rowHeight={rowHeight}
            sx={{
                border: 0, borderRadius: 3, boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)',
            }}
            slots={slots}
            slotProps={{
                pagination: {
                    sx: {
                        '.MuiTablePagination-toolbar': {
                            height: 30,
                        },
                        '.MuiTablePagination-selectLabel': {
                            color: 'rgba(0, 0, 0, 0.60)',
                            fontFamily: 'Zain',
                            fontSize: 16,
                        },
                        '.MuiTablePagination-select': {
                            color: 'rgba(0, 0, 0, 0.60)',
                            fontFamily: 'Zain',
                            fontSize: 14,
                            // marginTop: 0.5,
                            marginLeft: 0,
                        },
                        '.MuiSelect-icon': {
                            color: 'rgba(0, 0, 0, 0.60)',
                            fontFamily: 'Zain',
                            fontSize: 18,
                            marginRight: 0.5,
                        },
                        '.MuiTablePagination-displayedRows': {
                            color: 'rgba(0, 0, 0, 0.87)',
                            fontFamily: 'Zain',
                            fontSize: 16,
                            marginLeft: -3,
                            // marginTop: 0,
                            // marginBottom: 0,
                            // marginBottom: 1.5,
                        },
                    },
                },
            }}
        />
    </>);
};

export default CustomDataGridType1;