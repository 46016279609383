// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * @author Nick M.
 * @create date 2024-09-10
 * @desc Base styles for the component
 */

.UploadFiles_iconContainer__KheJt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.UploadFiles_horizontalContainer__KVs\\+C {
    display: flex;
    align-items: center;
    gap: 4px;
}

.UploadFiles_container__r-1tS {
    display: flex;
    padding: var(--3, 24px) var(--2, 16px);
    flex-direction: column;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    position: relative;
}`, "",{"version":3,"sources":["webpack://./src/components/SimpleUIComponents/UploadFiles/index.module.css"],"names":[],"mappings":"AAAA;;;;EAIE;;AAEF;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;AACZ;;AAEA;IACI,aAAa;IACb,sCAAsC;IACtC,sBAAsB;IACtB,mBAAmB;IACnB,QAAQ;IACR,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":["/**\n * @author Nick M.\n * @create date 2024-09-10\n * @desc Base styles for the component\n */\n\n.iconContainer {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n.horizontalContainer {\n    display: flex;\n    align-items: center;\n    gap: 4px;\n}\n\n.container {\n    display: flex;\n    padding: var(--3, 24px) var(--2, 16px);\n    flex-direction: column;\n    align-items: center;\n    gap: 8px;\n    align-self: stretch;\n    position: relative;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconContainer": `UploadFiles_iconContainer__KheJt`,
	"horizontalContainer": `UploadFiles_horizontalContainer__KVs+C`,
	"container": `UploadFiles_container__r-1tS`
};
export default ___CSS_LOADER_EXPORT___;
