import styles from '../../../CSS/SignIn/Container.module.css';

type Props = {
      title: string
}

const SignInSectionHeader: React.FC<Props> = ({title}) => {

return (
    <div className={styles.customSectionHeadings}>
    <div className={styles.box}>
          <div className={styles.titleAndContents}>
                <div className={styles.sectionHeading}>{title}</div>
          </div>
          <div className={styles.spacingHorizontal}>
                <div className={styles.frame}>
                      <div className={styles.spacer} />
                </div>
          </div>
    </div>
    <div className={styles.spacingVertical1}>
          <div className={styles.minHeight1} />
    </div>
    <div className={styles.dividerHorizontal}>
          <div className={styles.minHeight2} />
          <div className={styles.divider} />
    </div>
</div>
)
    
}

export default SignInSectionHeader;