import AuthService from "../services/auth/AuthService";
import AuthServiceRestImpl from "../services/auth/AuthServiceImpl";
import EventsService from "../services/events/eventsCrud/EventsService";
import EventsServiceRestImpl from "../services/events/eventsCrud/EventsServiceRestImpl";
import GoogleMapsService from "../services/events/googleMaps/GoogleMapsService";
import GoogleMapsServiceImpl from "../services/events/googleMaps/GoogleMapsServiceImpl";
import InvitService from "../services/REST/NetworkServiceInterface";
import InvitServiceRest from "../services/REST/NetworkServiceWorker";
const SECURE_VAR_CONFIG = process.env.REACT_APP_SECURE;
const HOST = process.env.REACT_APP_CONFIGURE_HOST;
const PORT = process.env.REACT_APP_CONFIGURE_BE_PORT;
export const googleMapsService: GoogleMapsService = new GoogleMapsServiceImpl();
export const eventsService: EventsService = new EventsServiceRestImpl(`${SECURE_VAR_CONFIG}`,`${HOST}:${PORT}`);
export const authService: AuthService = new AuthServiceRestImpl(`${SECURE_VAR_CONFIG}`,`${HOST}:${PORT}`);
export const NetworkService: InvitService = new InvitServiceRest(`${SECURE_VAR_CONFIG}`,`${HOST}:${PORT}`)