import { Box } from "@mui/material";
import FormsHeaderName from "../../../../components/Forms/FormsHeaderName";
import AddGuestsButton from "../Buttons/AddGuestsButton";
import { useTranslation } from 'react-i18next';

type Props = {
redirectToContacts: () => void
}

export const EmptyGuestListInGuestTab: React.FC<Props> = ({redirectToContacts}) => {
const { t } = useTranslation();

return <Box display="flex" flexDirection='column' justifyContent="center" alignItems="center" height="40vh">
    <FormsHeaderName headerName=
    {t('details.createguestlist.header.emptylist')}
    />
    <AddGuestsButton butName=
    {t('details.createguestlist.button.name.emptylist')}
    onClickFn={redirectToContacts}
    />
  </Box>

}


export default EmptyGuestListInGuestTab;

