/**
 * @author Nick M.
 * @create date 2024-09-10
 * @desc Upload image component with drag and drop area.
 */
import * as React from 'react';
import styles from './index.module.css'
import { styled } from '@mui/material/styles';
import { Box, Link, IconButton, Fade } from '@mui/material';
import { DeleteRounded } from '@mui/icons-material';
import UploadFile from '@mui/icons-material/UploadFile';
import { useDropzone } from 'react-dropzone'
import { TransitionGroup } from 'react-transition-group';
import { useTranslation } from 'react-i18next';

interface CustomButtonProps {
    fullWidth?: boolean;
    disabled?: boolean;
    children?: React.ReactNode;
}

const validMimeTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml'];
const maxFileSizeMB = 3 // Maximum file size (3 MB)
const maxFileSizeBite = maxFileSizeMB * 1024 * 1024;  // Maximum file size (3 MB)

export default function UploadImageExt() {

    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
    const [imageUrl, setImageUrl] = React.useState<string | null>(null);

    const onDrop = React.useCallback((files: any) => {

        if (files[0] === undefined) { return }

        const file = files[0]
        // Check MIME type
        if (!validMimeTypes.includes(file.type)) {
            const availableTypes = validMimeTypes.map((imgType) => { return imgType.split('/')[1] }).join(', ')
            setErrorMessage(`Invalid file type. Please select an image (${availableTypes}).`);
            setImageUrl(null);
            return;
        }

        // Check file size
        if (file.size > maxFileSizeBite) {
            setErrorMessage(`File is too large. Please upload an image smaller than ${maxFileSizeMB} MB.`);
            setImageUrl(null);
            return;
        }

        // If valid, reset error and set the image URL for preview
        setErrorMessage(null);
        setImageUrl(URL.createObjectURL(file));
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
    
    return (
        <TransitionGroup>
            <Fade in={true}>

                {imageUrl !== null
                    ?
                    (<div style={{ ...htmlStyles.photoPreviewContainer }}>
                        <img src={imageUrl} alt="Contact photo preview" width="120" />
                        <IconButton color="default" size="large" onClick={() => { setImageUrl(null) }}> <DeleteRounded /></IconButton>
                    </div>)
                    :
                    <Box component="section" sx={{ p: 2, border: `1px dashed ${errorMessage === null ? 'rgba(0, 0, 0, 0.12)' : 'red'}` }} {...getRootProps()}>
                        <input {...getInputProps()} />

                        <div className={styles.iconContainer}>
                            <UploadFile style={htmlStyles.iconColor} />
                        </div>
                        <div className={styles.container}>

                            <div className={styles.horizontalContainer}>
                                <Link
                                    component="label"
                                    variant="body2"
                                    role={undefined}
                                    style={{ ...htmlStyles.textFont, ...htmlStyles.uploadLinkColor }}>
                                    {t('modal.view.upload.form.button.title.upload')}
                                    <VisuallyHiddenInput
                                        type="file"
                                        onChange={(event) => console.log(event.target.files)}
                                    />
                                </Link>
                                <span style={{ ...htmlStyles.textFont, ...htmlStyles.uploadLinkDescColor }}>{t('modal.view.upload.form.link.description')}</span>
                            </div>
                            <span style={{ ...htmlStyles.textFont, ...htmlStyles.uploadDescriptionColor }}>{t('modal.view.upload.form.available.formats')}</span>
                            <Fade in={true}>
                                <span style={{ ...{ position: 'absolute', textAlign: 'center' }, ...htmlStyles.fileError }}>{errorMessage}</span>
                            </Fade>
                        </div>
                    </Box>
                }

            </Fade >
        </TransitionGroup>
    );
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const htmlStyles = {
    "textFont": {
        fontFamily: 'Zain',
        fontSize: 19,
        fontWtyle: 'normal',
        fontWeight: 400,
        lineHeight: '120%',
        letterSpacing: 0.15,
    },
    'uploadLinkColor': {
        color: '#1E2282',
    },
    'uploadLinkDescColor': {
        color: 'rgba(0, 0, 0, 0.87)',
    },
    'uploadDescriptionColor': {
        fontSize: 18,
        color: 'rgba(0, 0, 0, 0.60)'
    },
    'iconColor': {
        color: '#1E2282'
    },
    'fileError': {
        fontFamily: 'Zain',
        fontSize: 16,
        color: 'red',
        width: '100%',
        bottom: -4,
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    'photoPreviewContainer': {
        display: 'flex',
        paddingBottom: 8,
        alignItems: 'flex-start',
        gap: 8
    }
}
