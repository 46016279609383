import React from 'react';
import { DataGrid, GridSlots} from '@mui/x-data-grid';

  interface ComponentProps {
    rows?: readonly any[],
    columns?: any,
    rowHeight?: number,
    slots?: Partial<GridSlots> | undefined,
    paginationModel?: { page: number, pageSize: number },
    pageSizeOptions?: [number] | undefined
    componentsProps?: any;
    components?: any;
}

const CustomDataGridTypeEvents: React.FC<ComponentProps> = ({
    rows, columns, rowHeight = 70, slots,
    paginationModel = { page: 0, pageSize: 10 },
    pageSizeOptions = [5, 10, 15, 20, 25, 30],
}) => {
    return (<>
        <DataGrid 
            style={{
                position: 'absolute',
                maxHeight: '75%',
                width: '90%',
                backgroundColor: '#fff',
                marginTop: "115px",
              
            }}
            rows={rows}
            columns={columns}
            initialState={{ pagination: { paginationModel } }}
            pageSizeOptions={pageSizeOptions}
            rowHeight={rowHeight}
            sx={{
                border: 0, borderRadius: '0px 0px 4px 4px', boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)',
            }}
            slots={slots}
            slotProps={{
                pagination: {
                    sx: {
                        '.MuiTablePagination-toolbar': {
                            height: 30,
                        },
                        '.MuiTablePagination-selectLabel': {
                            color: 'rgba(0, 0, 0, 0.60)',
                            fontFamily: 'Zain',
                            fontSize: 16,
                        },
                        '.MuiTablePagination-select': {
                            color: 'rgba(0, 0, 0, 0.60)',
                            fontFamily: 'Zain',
                            fontSize: 14,
                            marginLeft: 0,
                        },
                        '.MuiSelect-icon': {
                            color: 'rgba(0, 0, 0, 0.60)',
                            fontFamily: 'Zain',
                            fontSize: 18,
                            marginRight: 0.5,
                        },
                        '.MuiTablePagination-displayedRows': {
                            color: 'rgba(0, 0, 0, 0.87)',
                            fontFamily: 'Zain',
                            fontSize: 16,
                            marginLeft: -3,
                          
                        },
                    },
                },
            }}
        />
    </>);
};

export default CustomDataGridTypeEvents;