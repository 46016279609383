import { Box, Card, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import eventsConf from '../../../../configCommon/events-config.json'
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";
import { eventsService } from "../../../../configCommon/service-config";
import { useSelectorAuth } from "../../../../redux/store";


type SearchProps = {
    handleSearch:(e: React.ChangeEvent<HTMLInputElement>) => void,
    searchParam:string,
    searchParamGuest:string,
    handleFilterGuest:(e: any) => void
  }

const SearchTool: React.FC<SearchProps> = ({handleSearch, searchParam, handleFilterGuest, searchParamGuest}) =>  {
    const { t } = useTranslation();
    const [allGuests, setAllMainGuests] = useState<any[]>([]);
    const curUser = useSelectorAuth();
  
  useEffect(() => {
    fetchData();
    }, [])
  
  
    const fetchData = async () => {
    try {
      const allMainGuests = await eventsService.getAllMainGuests();
      setAllMainGuests(allMainGuests);
      
      } catch (error) {
        console.error('Error fetching data:', error)  
      }
        
    }
    
    return <Card sx={{ 
        position: 'absolute',
        height: '90px',
        width: '90%',
        backgroundColor: '#fff',
        border: 0,
        borderRadius: '4px 4px 0px 0px',
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12),0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)',
        marginTop: "25px"
    }} 
    >
    
   <Box display='flex' flexDirection='row' gap={2}>
        <TextField
        fullWidth
        onChange={handleSearch} 
        value={searchParam}
        label= {t('events.page.textfield.label.search')}
        placeholder={t('events.page.textfield.placeholder.searchby')}
        variant="outlined"
        size="small" 
        InputLabelProps={{
            sx: {
              fontFamily: 'Zain',
              fontSize: '1.2rem',
    
            }
          }}
          InputProps={{
            sx: {
              fontFamily: 'Zain',
              fontSize: '1.2rem',
              opacity: 1
            },
          }}
          sx={{
            '& .MuiInputBase-root': {
              minHeight: '24px',
            },
            width: '270px',
            marginTop: '16px',
            marginLeft: '16px',

          }}>
           </TextField>
{curUser?.pddUser !== '0' &&


<FormControl
sx={{
  marginTop: '16px',
  marginRight: '16px',
  width: '270px'
}}
fullWidth
>
<InputLabel
  sx={{
    fontFamily: 'Zain',
    fontSize: 20,
    lineHeight: '15px',
  }}
>
  {t('events.page.formcontrol.inputlabel.mainguest')}
</InputLabel>
<Select
  value={searchParamGuest}
  onChange={handleFilterGuest}
  sx={{
    fontFamily: "Zain",
    fontSize: 20,
    minHeight: '44px',
    height: '44px',
    lineHeight: '44px',
  }}
  name="mainGuest"
  label="Main guest"
>
  <MenuItem value="all"
    sx={{
      fontFamily: "Zain",
      fontSize: 20,
      minHeight: '24px',
      height: '24px',
    }}
  >
      {t('events.page.formcontrol.menuitem.allguests')}

    
  </MenuItem>
  {allGuests.map((n) => (
    <MenuItem
      sx={{
        fontFamily: 'Zain',
        fontSize: 20,
        minHeight: '24px',
        height: '24px',
      }}
      value={n.nameEn}
      key={n._id}>
      {n.nameAr}
    </MenuItem>
  ))}
</Select>
</FormControl>


}

                    </Box>



  </Card>

}


export default SearchTool;