import i18n from 'i18next';
import enTranslation from './Locales/en/translation.json';
import arTranslation from './Locales/ar/translation.json';
import { initReactI18next } from 'react-i18next';

const defaultLanguage = 'ar';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: enTranslation
            },
            ar: {
                translation: arTranslation
            }
        },
        lng: defaultLanguage, // Default language
        fallbackLng: defaultLanguage, // Fallback language if translation is missing
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;


export { };

