import axios from "axios";
import GoogleMapsService from "./GoogleMapsService";
const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY!;
const GOOGLE_MAPS_BASE_URL = process.env.REACT_APP_GOOGLE_MAPS_BASE_URL!

export default class GoogleMapsServiceImpl implements GoogleMapsService {
    private baseUrl;
    constructor() {
        this.baseUrl = GOOGLE_MAPS_BASE_URL;
    }
    
    async getLocation(lat: string, lng: string): Promise<string> {
    const url = new URL(this.baseUrl);
    url.searchParams.append('latlng', `${lat},${lng}`);
    url.searchParams.append('key', API_KEY);
    const urlString = url.toString();  
    const response = await axios.get(urlString);
    const address = response.data.results[0]?.formatted_address || '';
    return address;
    }


    async getCoordinates(address: string): Promise<{ lat: number, lng: number } | null> {
        const url = new URL(this.baseUrl);
        url.searchParams.append('address', address);
        url.searchParams.append('key', API_KEY);
    
        try {
            const response = await axios.get(url.toString());
            const location = response.data.results[0]?.geometry.location;
            
            if (location) {
                return {
                    lat: location.lat,
                    lng: location.lng
                };
            } else {
                return null; // If no location found
            }
        } catch (error) {
            console.error('Error retrieving coordinates:', error);
            return null;
        }
    }
    



    

}

