/**
 * @author Nick M.
 * @create date 2024-09-09
 * @desc Group create and update modal view.
 */

import * as React from 'react';
import styles from './index.module.css';
import clsx from 'clsx';
import { RemoveRounded } from '@mui/icons-material';
import { styled, css } from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';
import { TextField, MenuItem, Box, Paper, IconButton, Typography, Grid2 } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import DefaultCloseButton from '../../../components/SimpleUIComponents/DefaultButtons/DefaultCloseButton';
import ContactGroupsConfig from '../../../configCommon/ContactsGroupsConfig.json';
import ButtonExt, { ButtonType, VarianType } from '../../../components/SimpleUIComponents/Button/ButtonExt';
import { NetworkService } from '../../../configCommon/service-config';
import ContactsGroupML from '../../../model/ContactsGroups/ContactsGroupML';
import ContactML from '../../../model/Contact/ContactML';
import SearchInput from '../../../components/SimpleUIComponents/SearchInput';
import SearchInputML from '../../../model/SearchInputML';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
    open: boolean,
    group?: ContactsGroupML,
    handleClose?: (group?: ContactsGroupML) => void
}

export default function GroupModalView({ open = false, handleClose, group }: ComponentProps) {
    const { t } = useTranslation();

    const [contacts, setContacts] = React.useState<ContactML[] | null>(null);
    const [networkError, setNetworkError] = React.useState<string | unknown>(null);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [selectedContacts, setSelectedContacts] = React.useState<ContactML[]>(group?.contacts ?? []);
    const [selectedContacts2, setSelectedContacts2] = React.useState<SearchInputML[]>();

    // Initialize form values with contact data or defaults
    const [formValues, setFormValues] = React.useState({
        name: group?.name ?? "",
        color: group?.color
    });

    const { ContactGroupsAvailableColors } = ContactGroupsConfig;

    const childRef = React.useRef<any>(null);

    React.useEffect(() => {

        const fetchData = async () => {
            try {
                // Fetch both contact groups and prefixes (concurrently)
                const [contacts] = await Promise.all([
                    NetworkService.getAllContacts(),
                ]);

                setSelectedContacts2(convertContactsModelToAutocompleteModel(contacts))

                // Update state with the fetched data
                setContacts(contacts);
            } catch (error) {
                setNetworkError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const isEnableCreate = !(formValues.name.length >= 2)
    const isEdit = group !== undefined; //// Determine if the form is in "edit" mode (if a group is provided)

    // Handle form field changes
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        // Update form values with the changed input
        setFormValues(prevValues => ({ ...prevValues, [name]: value }));
    };

    // Handle changes to selected contact groups
    const handleContactsChange = (event: React.SyntheticEvent, newData: SearchInputML[]) => {
        const selectedContactsList = newData.map(data => data.data as ContactML);
        setSelectedContacts(selectedContactsList);
    };

    // Remove option from the selected contacts list. This function is called a method from the child `Search Input` component for correct work.
    const handleRemove = (contact: ContactML) => {
        const removeContact = selectedContacts2?.find((item) => item.id === contact._id);
        if (childRef.current && removeContact) {
            childRef.current.removeOptionBy(removeContact);
        }
    };

    // Handle form submission for creating or updating a contact
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        // Check if any groups were selected that are not already associated with the contact
        const selectedContactsIds = selectedContacts?.map(contact => contact._id) ?? [];
        const diffSelectedGroups = (selectedContactsIds?.filter(group1 => !group?.contacts?.some(group2 => group2._id === group1))?.length ?? 0) > 0 || selectedContactsIds?.length !== group?.contacts?.length
        const isRemoveAllGroups = selectedContacts?.length === 0

        // Prepare the contact data including selected groups if any
        const saveData = {
            group: formValues,
            ...(diffSelectedGroups && { contacts: selectedContactsIds }),
            ...(isRemoveAllGroups && { contacts: [] })
        };

        try {

            let newGroup = undefined;
            // If editing a contact, send a PUT request; otherwise, POST a new contact
            if (isEdit && group) {
                const updatedContact = await NetworkService.putContactsGroup(group._id, saveData);
                newGroup = updatedContact

                if (newGroup) {
                    newGroup.contacts = contacts?.filter(contact => selectedContactsIds?.includes(contact._id));
                }
            } else {
                newGroup = await NetworkService.postAddNewContactsGroup(saveData);
            }
            handleClose && handleClose(newGroup)
        } catch (error) {
            console.log(error);
        }
    };

    const columns: GridColDef[] = [
        {
            field: 'fullName', flex: 0.45, renderCell(params) {
                return (
                    <Grid2 container sx={{ justifyContent: "left" }}>
                        <Box sx={{ marginLeft: 0.35 }}><span className={styles.columnFont}>{params.value}</span></Box>
                    </Grid2>
                );
            }
        },
        {
            field: 'contact', headerName: 'Contacts', flex: 0.4, renderCell(params) {
                const phone = params.value.phone;
                const email = params.value.email;
                return (
                    <Grid2 container>
                        {phone !== undefined && email !== undefined && phone.length > 0 && email.length > 0
                            ? <Box sx={{ marginTop: 1 }}>
                                <Typography sx={{ fontFamily: 'Zain', fontSize: 18, lineHeight: 1 }}>{phone}</Typography>
                                <Typography sx={{ fontFamily: 'Zain', fontSize: 18 }}>{email}</Typography>
                            </Box>
                            : <span className={styles.columnFont}>{phone ?? email}</span>
                        }
                    </Grid2>
                );
            }
        },
        {
            field: 'remove', type: 'actions', flex: 0.15, renderCell(params) {
                return (
                    <Grid2 container sx={{ justifyContent: "center" }}>
                        <Box>
                            <IconButton onClick={() => { params.value.func(params.value.model) }} color="default" size="medium"><RemoveRounded /></IconButton>
                        </Box>
                    </Grid2>
                )
            }
        }
    ];

    const rows = selectedContacts?.map((contact) => {
        return {
            id: contact._id,
            fullName: contact.full_name,
            contact: { 'phone': contact.phone, 'email': contact.email },
            remove: { 'func': handleRemove, 'model': contact }
        }
    });

    const paginationModel = { page: 0, pageSize: 7 };

    return (<Modal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        slots={{ backdrop: StyledBackdrop }}
    >
        <Box sx={{ ...commonStyles }} component="form" onSubmit={handleSubmit}>
            <ModalContent>
                <div className={styles.modalTopTitle}>
                    <h2 id="unstyled-modal-title" className="modal-title">
                        {isEdit ? t('modal.view.title.edit.group') : t('modal.view.title.new.group')}
                    </h2>
                    <div className={styles.modalTopCloseBtn}>
                        <DefaultCloseButton onClick={handleClose} color={'success'} />
                    </div>
                </div>
                <div style={{ flexGrow: 1, overflowY: 'auto' }}>
                    <TextField
                        name='name' sx={{ marginBottom: 2 }}
                        defaultValue={group?.name}
                        onChange={handleChange}
                        label={t('modal.view.title.new.group')}
                        placeholder={t('modal.view.input.name.plhd')}
                        variant="outlined"
                        size="medium"
                        fullWidth
                        margin="dense"
                        type='text'
                        required />

                    <TextField
                        id="outlined-select-currency"
                        name='color'
                        fullWidth
                        onChange={handleChange}
                        select
                        label={t('modal.view.input.color.label')}
                        variant="outlined"
                        defaultValue={group?.color || -1}
                        margin="dense"
                        size="medium"
                        sx={{ marginBottom: 2 }}
                    >
                        <MenuItem key={-1} value={-1}>{t('modal.view.input.color.plhd')}</MenuItem>
                        {ContactGroupsAvailableColors.map((option) => (
                            <MenuItem key={option.hex} value={option.hex}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignSelf: 'stretch'
                                }}>
                                    <span style={{ width: 16, height: 16, backgroundColor: option.hex, marginRight: 8, borderRadius: 4 }}></span> {t(option.name)}
                                </div>
                            </MenuItem>
                        ))}
                    </TextField>
                    {(selectedContacts2?.length ?? 0) > 0 &&
                        <SearchInput
                            ref={childRef}
                            title={{ label: t('modal.view.input.add.contactto.group.label'), placeholder: t('modal.view.input.add.contactto.group.plhd') }}
                            listData={selectedContacts2!}
                            selectedOptions={convertContactsModelToAutocompleteModel(selectedContacts)}
                            onChange={handleContactsChange}
                        />
                    }
                    {(selectedContacts?.length ?? 0) > 0 &&
                        <Paper sx={{
                            minHeight: 100,
                            height: '100%',
                            width: '100%',
                            marginTop: 2
                        }}>
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                initialState={{ pagination: { paginationModel } }}
                                slots={{ columnHeaders: () => null, }}
                                sx={{ border: 1, borderColor: 'rgba(0, 0, 0, 0.16)' }}
                            />
                        </Paper>
                    }
                </div>
                <div className={styles.modalHorizontalBottomContainer}>
                    <ButtonExt variant={VarianType.outlined} onClick={handleClose}>{t('general.cancel').toUpperCase()}</ButtonExt>
                    <ButtonExt variant={VarianType.contained} type={ButtonType.submit} disabled={isEnableCreate}>{(isEdit ? t('general.save') : t('general.create')).toUpperCase()}</ButtonExt>
                </div>
            </ModalContent>
        </Box>
    </Modal >);
}

function convertContactsModelToAutocompleteModel(contacts: ContactML[] | null | undefined) {
    return contacts?.map(contact => { return { id: contact._id, title: contact.full_name, data: contact } }) ?? [];
}

const Backdrop = React.forwardRef<HTMLDivElement, { open?: boolean; className: string }>
    ((props, ref) => {
        const { open, className, ...other } = props;
        return (
            <div
                className={clsx({ 'base-Backdrop-open': open }, className)}
                ref={ref}
                {...other}
            />
        );
    });

const blue = {
    200: '#99CCFF',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0066CC',
};

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};

const commonStyles = {
    borderRadius: 6,
    overflow: 'hidden',
    maxHeight: '90%',
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled('div')(
    ({  }) => css`
    font-family: 'Zain', sans-serif;
    font-weight: 500;
    width: 432px;
    outline: 0;
    text-align: start;
    position: relative;
    gap: 8px;
    background-color: #fff;
    border: 0px solid ${grey[200]};
    box-shadow: 0 4px 12px rgb(0 0 0 / 0.2);
    padding: 24px;
    color: ${grey[900]};
    display: flex;
    flex-direction: column;
    max-height: 85vh;

    & .modal-title {
      font-family: 'Zain', sans-serif;
      font-size: 40px;
      font-weight: 400;
      margin: 0;
      line-height: 110%;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
    color: ${grey[800]};
      margin-bottom: 4px;
    }
  `,
);

const TriggerButton = styled('button')(
    ({  }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5;
    padding: 8px 16px;
    border-radius: 8px;
    transition: all 150ms ease;
    cursor: pointer;
        background: #fff;
    border: 1px solid ${grey[200]};
    color: ${grey[900]}
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

    &:hover {
    background: ${grey[50]};
      border-color: ${grey[300]};
    }

    &:active {
    background: ${grey[100]};
    }

    &:focus-visible {
    box-shadow: 0 0 0 4px ${blue[200]};
      outline: none;
    }
  `,
);

