import Evnt from "../../../model/Event/Evnt";
import { fetchGetData, fetchPostData, fetchPutData } from "../../../utils/restFuncs/fetch";
import EventsService from "./EventsService";
const SERVER_NOT_AVAILABLE = 'Server is unavailable, repeat later on';

export default class EventsServiceRestImpl implements EventsService {
    url: string;
    constructor(secure: string, host: string) {
        this.url = `${secure}://${host}`;
    }
    
    addEvent(ev: Evnt): Promise<{}> {
        return fetchPostData<Evnt>(this.url, ev, SERVER_NOT_AVAILABLE, 'events/new/add');
    }

    getEventById(id: string): Promise<Evnt> {
        return fetchGetData<Evnt>(this.url, SERVER_NOT_AVAILABLE, `events/details/${id}`)
    }

    getEventExtDetails(id: string): Promise<Evnt> {
         return fetchGetData<Evnt>(this.url, SERVER_NOT_AVAILABLE, `events/extdetails/${id}`)
    }


    updateEvent(id: string, ev: Evnt) {
        return fetchPutData<Evnt>(this.url, ev, SERVER_NOT_AVAILABLE, `events/update/${id}`)

    }

    getAllEvents() {
        return fetchGetData<Evnt[]>(this.url, SERVER_NOT_AVAILABLE, 'events/all')
    }

    getAllMainGuests() {
        return fetchGetData<any[]>(this.url, SERVER_NOT_AVAILABLE, 'events/mainguests')
    } 

    
    getAllEventTypes() {
        return fetchGetData<any[]>(this.url, SERVER_NOT_AVAILABLE, 'events/types')
    }



}