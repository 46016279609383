
export async function fetchGetData<T>(url: string, errMsg: string, endpoint?: string): Promise<T> {
  try {
    const response = await fetch(`${url}/${endpoint}`, {credentials: 'include'});
    if (response.status == 401) {
      window.location.href = '/'
      return await response.json();
    }
    
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error('Failed to get data: ' + response.statusText);
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw new Error(errMsg);
  }
}

export async function fetchPostData<T>(url: string, body: any, errMsg: string, endpoint?: string): Promise<T> {
  try {
    const request = new Request(`${url}/${endpoint}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: "POST",
      body: JSON.stringify(body),
    });
    const response = await fetch(request, {credentials: 'include'});
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error('Failed to post data: ' + response.statusText);
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw new Error(errMsg);
  }
} 

export async function fetchPutData<T>(url: string, body: any, errMsg: string, endpoint?: string): Promise<T> {
  try {
    const request = new Request(`${url}/${endpoint}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: "PUT",
      body: JSON.stringify(body),
    });
    const response = await fetch(request, {credentials: 'include'});
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error('Failed to put data: ' + response.statusText);
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw new Error(errMsg);
  }
}
