import { Box, Divider } from '@mui/material';
import HeaderName from '../HeaderListsCommonComps/HeaderName';

export default function TopHeaderWithoutTabs({ pageName, pageTopButtons, components, returnButton, fn }: any) {
    return (
        <Box sx={mainBoxStyle}>
            <Box sx={subMainBoxStyle}>
                <Box sx={mainHeaderStyle}>
                    <Box sx={buttonHeaderContainer}>
                        {returnButton}
                        <Box sx={spaceBetwButtonHeader}>
                            <HeaderName headerName={pageName} />
                        </Box>
                    </Box>
                    {pageTopButtons}
                </Box>
            </Box>
            <Divider />
            {typeof components === 'function' ? components(fn) : components}
            <Box sx={bottomContainerStyle}></Box>
        </Box >
    );
}

const buttonHeaderContainer = {
    flexDirection: 'row', display: 'inline-flex'
}

const spaceBetwButtonHeader = {
    ml: '-12px'
}

const mainBoxStyle = {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100vh',
    maxWidth: '100%'
}

const subMainBoxStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    alignSelf: 'stretch',
    flexShrink: 0
}

const mainHeaderStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
}

const bottomContainerStyle = {
    display: 'flex',
    alignSelf: 'stretch',
    // backgroundColor: 'red',
    // height: 10,
    flexShrink: 0
}