/**
 * @author Nick M.
 * @create date 2024-09-09
 * @desc Add new contact
 */

import * as React from 'react';
import styles from './index.module.css';
import clsx from 'clsx';
import { styled, css, Theme } from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';
import { TextField, MenuItem, Box, Divider, Typography, FormControlLabel, Checkbox, FormLabel } from '@mui/material';
import DefaultCloseButton from '../../../components/SimpleUIComponents/DefaultButtons/DefaultCloseButton';
import TextFieldTags from '../../../components/SimpleUIComponents/TextFields/TextFieldTags';
import ButtonExt, { ButtonType, VarianType } from '../../../components/SimpleUIComponents/Button/ButtonExt';
import UploadImageExt from '../../../components/SimpleUIComponents/UploadFiles/UploadImageExt';
import { NetworkService } from '../../../configCommon/service-config';
import ContactPrefixML from '../../../model/Contact/ContactPrefixML';
import ContactsGroupML from '../../../model/ContactsGroups/ContactsGroupML';
import ContactML from '../../../model/Contact/ContactML'
import { useTranslation } from 'react-i18next';
import { PersonAddRounded, PersonOffRounded } from '@mui/icons-material';

interface ComponentProps {
    open: boolean,
    contact?: ContactML,
    handleClose?: (contact?: ContactML) => void
}

export default function ContactModalView({ open = false, handleClose, contact }: ComponentProps) {

    const { t } = useTranslation();
    const [contactGroups, setContactGroups] = React.useState<ContactsGroupML[] | null>(null);
    const [contactPrefix, setContactPrefix] = React.useState<ContactPrefixML[] | null>(null);
    const [networkError, setNetworkError] = React.useState<string | unknown>(null);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [selectedGroups, setSelectedGroups] = React.useState<string[]>();
    const [isShowPA, setIsShowPA] = React.useState<boolean>((contact?.pa_full_name?.length ?? 0) > 1);

    // Initialize form values with contact data or defaults
    const [formValues, setFormValues] = React.useState({
        email: contact?.email ?? "",
        phone: contact?.phone ?? "",
        full_name: contact?.full_name ?? "",
        prefix_uid: contact?.prefix_uid ?? ""
    });

    const [paFormValues, setPAFormValues] = React.useState({
        pa_email: contact?.pa_email ?? "",
        pa_phone: contact?.pa_phone ?? "",
        pa_full_name: contact?.pa_full_name ?? "",
        invitation_rules: {
            send_invitation_to_pa: contact?.invitation_rules?.send_invitation_to_pa ?? false,
            send_invitation_to_contact: contact?.invitation_rules?.send_invitation_to_contact ?? false
        }
    });

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch both contact groups and prefixes (concurrently)
                const [contactGroups, contactPrefix] = await Promise.all([
                    NetworkService.getAllContactsGroups(),
                    NetworkService.getAllContactsPrefixs()
                ]);

                setSelectedGroups(contact?.groups?.map((group) => { return group._id }));
                // Update state with the fetched data
                setContactGroups(contactGroups);
                setContactPrefix(contactPrefix);
            } catch (error) {
                setNetworkError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    // Check if the form is valid for creating a new contact
    const isEnableCreateNewContact = checkRequiredFillFields()
    const isEditContact = contact !== undefined; // Determine if the form is in "edit" mode (if a contact is provided)

    function checkRequiredFillFields() {
        return !(formValues.phone.length >= 8 && formValues.full_name.length > 1 && (isShowPA ? (paFormValues.pa_phone.length >= 8 && paFormValues.pa_full_name.length > 1) : true))
    };

    // Handle show personal assistant
    const handleShopPA = () => {
        setIsShowPA(!isShowPA);
    };

    const onChangeCheckBox = (name: string, checked: boolean) => {
        setPAFormValues(prevValues => ({ ...prevValues, invitation_rules: { ...prevValues.invitation_rules, [name]: checked } }));
    };

    // Handle form field changes
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        // Update form values with the changed input
        setFormValues(prevValues => ({ ...prevValues, [name]: value }));
        setPAFormValues(prevValues => ({ ...prevValues, [name]: value }));
    };

    // Handle changes to selected contact groups
    const handleGroupChange = (newData: ContactsGroupML[]) => {
        setSelectedGroups(newData.map((group) => { return group._id }));
    };

    // Handle form submission for creating or updating a contact
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        // Check if any groups were selected that are not already associated with the contact
        const diffSelectedGroups = (selectedGroups?.filter(group1 => !contact?.groups?.some(group2 => group2._id === group1))?.length ?? 0) > 0 || selectedGroups?.length !== contact?.groups?.length
        const isRemoveAllGroups = selectedGroups?.length === 0

        // Prepare the contact data including selected groups if any
        const contactFormValue = { ...formValues, ...(isShowPA ? paFormValues : {}) };
        const contactData = {
            contact: contactFormValue,
            ...(diffSelectedGroups && { groups: selectedGroups }),
            ...(isRemoveAllGroups && { groups: [] })
        };

        try {
            let newContact = undefined;
            // If editing a contact, send a PUT request; otherwise, POST a new contact
            if (isEditContact && contact) {
                const updatedContact = await NetworkService.putContact(contact._id, contactData);
                newContact = updatedContact

                if (newContact) {
                    newContact.prefix = contactPrefix?.find(prefix => prefix._id === contact?.prefix_uid);
                    newContact.groups = contactGroups?.filter(group => selectedGroups?.includes(group._id));
                }
            } else {
                newContact = await NetworkService.postAddNewContact(contactData);
            }
            handleClose && handleClose(newContact)
        } catch (error) {
            console.log(error);
        }
    };

    return (<Modal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        slots={{ backdrop: StyledBackdrop }}
    >
        <Box sx={{ ...commonStyles }} component="form" onSubmit={handleSubmit}>
            <ModalContent>
                <div className={styles.modalTopTitle}>
                    <h2 id="unstyled-modal-title" className="modal-title">
                        {isEditContact ? t('modal.view.title.edit.contact') : t('modal.view.title.new.contact')}
                    </h2>
                    <div className={styles.modalTopCloseBtn}>
                        <DefaultCloseButton onClick={handleClose} color={'success'} />
                    </div>
                </div>
                <div style={{ flexGrow: 1, overflowY: 'auto', paddingBottom: 16 }}>
                    <UploadImageExt />
                    <div className={styles.modalHorizontalContainer}>
                        <TextField
                            id="outlined-select-currency"
                            name='prefix_uid'
                            onChange={handleChange}
                            select
                            label={t('modal.view.input.label.prefix')}
                            variant="outlined"
                            defaultValue={contact?.prefix?._id || -1}
                            sx={{ maxWidth: 130, minWidth: 84, marginTop: 2 }}
                        >
                            <MenuItem key={-1} value={-1}>{t('modal.view.input.prefix.placeholder')}</MenuItem>
                            {contactPrefix?.map((option) => (
                                <MenuItem key={option._id} value={option._id}>
                                    {option.prefix_name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField name='full_name' sx={{ marginTop: 2 }} defaultValue={contact?.full_name} onChange={handleChange} label={t('modal.view.input.full.name.label')} placeholder={t('modal.view.input.full.name.placeholder')} variant="outlined" size="medium" fullWidth type='text' required />
                    </div>
                    <TextField fullWidth name='email' sx={defaultTopMargin} defaultValue={contact?.email} onChange={handleChange} label={t('modal.view.input.email.label')} placeholder={t('modal.view.input.email.placeholder')} variant="outlined" size="medium" type='email' />
                    <TextField fullWidth name='phone' sx={defaultTopMargin} defaultValue={contact?.phone} onChange={handleChange} label={t('modal.view.input.phone.number.label')} placeholder={t('modal.view.input.phone.number.placeholder')} variant="outlined" size="medium" type='number' required />
                    {contactGroups !== null &&
                        <TextFieldTags sx={defaultTopMargin} list={contactGroups} contactGroups={contact?.groups} onChange={(event, newValue) => { handleGroupChange(newValue) }} />
                    }

                    {isShowPA &&
                        <Box>
                            <Typography variant='h6' sx={personalAssistanContainer}>{t('modal.view.new.pa.title')}</Typography>
                            <TextField name='pa_full_name' sx={{ marginTop: 2 }} defaultValue={contact?.pa_full_name} onChange={handleChange} label={t('modal.view.new.contact.pa.input.label')} placeholder={t('modal.view.new.contact.pa.input.placeholder')} variant="outlined" size="medium" fullWidth type='text' required />
                            <TextField fullWidth name='pa_email' sx={defaultTopMargin} defaultValue={contact?.pa_email} onChange={handleChange} label={t('modal.view.new.contact.pa.input.email.label')} placeholder={t('modal.view.new.contact.pa.input.email.placeholder')} variant="outlined" size="medium" type='email' />
                            <TextField fullWidth name='pa_phone' sx={defaultTopMargin} defaultValue={contact?.pa_phone} onChange={handleChange} label={t('modal.view.new.contact.pa.input.phone.label')} placeholder={t('modal.view.new.contact.pa.input.phone.placeholder')} variant="outlined" size="medium" type='number' required />

                            <Typography variant='body2' sx={defaultCheckBoxTitle}>{t('modal.view.new.contact.pa.invitation.rule.title')}</Typography>
                            <Box className={styles.modalVerticalContainer}>
                                <FormControlLabel
                                    sx={{ marginLeft: 0.2 }}
                                    control={<Checkbox color='success' defaultChecked={contact?.invitation_rules?.send_invitation_to_pa} size='small' onChange={e => onChangeCheckBox('send_invitation_to_pa', e.target.checked)} />}

                                    labelPlacement='end'
                                    label={<Typography sx={defaultCheckBoxContainer}>{t('modal.view.new.contact.pa.invitation.rule.send.to.pa')}</Typography>}
                                />
                                <FormControlLabel
                                    sx={{ marginLeft: 0.2 }}
                                    control={<Checkbox color='success' defaultChecked={contact?.invitation_rules?.send_invitation_to_contact} size='small' onChange={e => onChangeCheckBox('send_invitation_to_contact', e.target.checked)} />}
                                    labelPlacement='end'
                                    label={<Typography sx={defaultCheckBoxContainer}>{t('modal.view.new.contact.pa.invitation.rule.send.to.contact')}</Typography>}
                                />
                            </Box>
                        </Box>
                    }
                    <Box className={styles.modalHorizontalBottomContainer}>
                        {isShowPA
                            ? <ButtonExt color='red' variant={VarianType.outlined} onClick={handleShopPA}>
                                <PersonOffRounded sx={{ marginLeft: 1, marginRight: 1 }} />
                                {t('new.contact.modal.remove.assistant').toUpperCase()}
                            </ButtonExt>
                            : <ButtonExt variant={VarianType.outlined} onClick={handleShopPA}>
                                <PersonAddRounded sx={{ marginLeft: 1, marginRight: 1 }} />
                                {t('new.contact.modal.add.assistant').toUpperCase()}
                            </ButtonExt>
                        }
                    </Box>
                </div>
                <Box>
                    <Divider style={{ width: '200%', marginLeft: -100 }} />
                    <div className={styles.modalHorizontalBottomContainer}>
                        <ButtonExt variant={VarianType.outlined} onClick={handleClose}>{t('general.cancel').toUpperCase()}</ButtonExt>
                        <ButtonExt variant={VarianType.contained} type={ButtonType.submit} disabled={isEnableCreateNewContact}>{(isEditContact ? t('general.save') : t('general.create')).toUpperCase()}</ButtonExt>
                    </div>
                </Box>
            </ModalContent>
        </Box>
    </Modal>);
}

const defaultTopMargin = {
    marginTop: 2.5
};

const defaultCheckBoxTitle = {
    fontFamily: 'Zain', fontSize: 20, color: 'rgba(0, 0, 0, 0.87)', marginTop: 2.5
};

const defaultCheckBoxContainer = {
    fontFamily: 'Zain', fontSize: 20, color: 'rgba(0, 0, 0, 0.87)'
};

const personalAssistanContainer = {
    paddingTop: 1.5, fontFamily: 'Zain', fontSize: 24
};

const Backdrop = React.forwardRef<HTMLDivElement, { open?: boolean; className: string }>
    ((props, ref) => {
        const { open, className, ...other } = props;
        return (
            <div
                className={clsx({ 'base-Backdrop-open': open }, className)}
                ref={ref}
                {...other}
            />
        );
    });

const blue = {
    200: '#99CCFF',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0066CC',
};

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};

const commonStyles = {
    borderRadius: 6,
    overflow: 'hidden',
    maxHeight: '90%',
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled('div')(
    ({  }) => css`
    font-family: 'Zain', sans-serif;
    font-weight: 500;
    width: 432px;
    outline: 0;
    text-align: start;
    position: relative;
    gap: 8px;
    background-color: #fff;
    border: 0px solid ${grey[200]};
    box-shadow: 0 4px 12px rgb(0 0 0 / 0.2);
    padding: 24px;
    color: ${grey[900]};
    display: flex;
    flex-direction: column;
    max-height: 85vh;

    & .modal-title {
      font-family: 'Zain', sans-serif;
      font-size: 40px;
      font-weight: 400;
      margin: 0;
      line-height: 110%;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
    color: ${grey[800]};
      margin-bottom: 4px;
    }
  `,
);

const TriggerButton = styled('button')(
    ({  }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5;
    padding: 8px 16px;
    border-radius: 8px;
    transition: all 150ms ease;
    cursor: pointer;
        background: #fff;
    border: 1px solid ${grey[200]};
    color: ${grey[900]}
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

    &:hover {
    background: ${grey[50]};
      border-color: ${grey[300]};
    }

    &:active {
    background: ${grey[100]};
    }

    &:focus-visible {
    box-shadow: 0 0 0 4px ${blue[200]};
      outline: none;
    }
  `,
);