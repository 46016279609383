/**
 * @author Nick M.
 * @create date 2024-09-10
 * @desc Customized button to our style guide.
 */

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export enum VarianType {
    contained = "contained",
    outlined = "outlined",
}

export enum ButtonType {
    submit = "submit",
    reset = "reset",
    button = "button"
}

interface CustomButtonProps {
    variant: VarianType;
    type?: ButtonType,
    fullWidth?: boolean;
    disabled?: boolean;
    onClick?: () => void,
    color?: string;
    children?: React.ReactNode;
}

function getColorPallet(color: string = '#1E2282', variant: VarianType, isDisabled = false) {
    const colorBorder = isDisabled ? 'rgba(0, 0, 0, 0.38)' : (variant === VarianType.outlined ? color : color)
    const colorBackground = isDisabled ? 'rgba(0, 0, 0, 0.12)' : (variant === VarianType.outlined ? '#FFF' : color)
    const colorText = isDisabled ? 'rgba(0, 0, 0, 0.38)' : (variant === VarianType.outlined ? color : '#FFF')

    return { "borderColor": colorBorder, "backgroundColor": colorBackground, "color": colorText }
}

export default function ButtonExt({ variant, type = undefined, fullWidth = true, disabled = false, onClick, color, children }: CustomButtonProps) {
    const borderColorAlpha = variant === VarianType.contained ? '0' : '0.50'
    return (
        <BootstrapButton sx={[{ border: `1px solid rgba(30, 34, 130, ${borderColorAlpha})` }, getColorPallet(color, variant, disabled)]} fullWidth={fullWidth} disabled={disabled} type={type} onClick={onClick}>
            {children}
        </BootstrapButton>
    );
}

const BootstrapButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 20,
    padding: '7px 12px',
    lineHeight: 1.5,
    borderRadius: 8,
    // border: '1.0px solid rgba(30, 34, 130, 0.50)',
    fontFamily: [
        'Zain',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(',')
});
