import * as React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import ContactML from '../../../../model/Contact/ContactML';
import AvatarNameRow from '../../../../components/CustomDataGrid/Rows/AvatarNameRow';
import ContactGroupsRow from '../../../../components/CustomDataGrid/Rows/ContactGroupsRow';
import ContactContactsRow from '../../../../components/CustomDataGrid/Rows/ContactContactsRow';
import { useTranslation } from 'react-i18next';
import SingleAddItemActionCell from '../../../../components/CustomDataGrid/EventsRows/SingleAddItemActionCell';
import CustomDataGridTypeContacts from '../../../../components/CustomDataGrid/CustomDataGridTypeContacts';

interface Props {
    contacts: ContactML[];
    onAddContact: (contact: ContactML) => void;
}

const Contacts: React.FC<Props> = ({ contacts, onAddContact }) => {
    const { t } = useTranslation();

    const columns: GridColDef[] = [
        AvatarNameRow({
            fId: "fullName",
            fFlex: 0.335,
            columnName: t("contacts.table.column.name.full.name"),
            marginL: 1,
        }),
        ContactGroupsRow({
            fId: "groups",
            fFlex: 0.3,
            columnName: t("contacts.table.column.name.group"),
        }),
        ContactContactsRow({
            fId: "contact",
            fFlex: 0.23,
            columnName: t("contacts.table.column.name.contacts"),
        }),
        SingleAddItemActionCell({
            fId: "addActions",
            fFlex: 0.035,
        })
    ];

    const rows = contacts?.map((contact) => ({
        id: contact._id,
        fullName: { avatar: contact.qr_code, fullName: contact.full_name },
        groups: contact.groups,
        contact: { phone: contact.phone, email: contact.email },
        addActions: {
            addfunc: () => onAddContact(contact),
            model: contact
        },
    }));

    return ( <>
            <CustomDataGridTypeContacts
            rows={rows}
            columns={columns}
            />
        </>
    );
};

export default Contacts;


