/**
 * @author Nick M.
 * @create date 2024-09-17
 * @desc `AvatarNameRow` is a functional component used to define a custom column for a data grid. 
 * This column renders an `avatar` and a `full name` for each row in the grid. It also customizes the column's header rendering, 
 * with optional margin controls for left and right spacing.
 *
 * The component accepts several properties (via the `ComponentProps` interface) for flexible configuration, including the 
 * column's field ID, flex value, type, column name, and optional margin values.
 * 
 * The function returns a column definition (`GridColDef`), which includes a custom header and a cell renderer that displays.
 * 
 * @param {ComponentProps} props - The properties passed to the component.
 * 
 * @property {string} fId - The unique field identifier for the column.
 * @property {number} fFlex - The flex value that controls the width of the column in the data grid.
 * @property {GridColType} [fType='custom'] - Optional. The type of the grid column, defaulting to 'custom'.
 * @property {string} [columnName] - Optional. The name to be displayed in the column's header.
 * @property {number} [marginL] - Optional. Left margin applied to the header.
 * @property {number} [marginR] - Optional. Right margin applied to the header.
 * 
 * @returns {GridColDef} - A grid column definition object containing the configuration for the `avatar` and `full name` column.
 */

import { GridColDef, GridColType } from '@mui/x-data-grid';
import { Avatar } from '@mui/material';
import CustomDataGridHeader from '../Headers/CustomDataGridHeader';

interface ComponentProps {
    fId: string,
    fFlex: number,
    fType?: GridColType,
    columnName?: string,
    marginL?: number,
    marginR?: number
}

export default function AvatarNameRow({ fId, fFlex, fType = 'custom', columnName, marginL, marginR }: ComponentProps) {
    return {
        field: fId, flex: fFlex, type: fType,
        renderHeader(_) { return (<CustomDataGridHeader name={columnName} ml={marginL} mr={marginR} />); },
        renderCell(params) {
            return (
                <>
                    <Avatar style={{ float: 'left', transform: 'translateY(35%)', marginRight: 10, marginLeft: 6 }} src={params.value.avatar} />
                    <div style={{ fontFamily: 'Zain', fontSize: 18 }} >{params.value.fullName}</div>
                </>
            );
        }
    } as GridColDef;
}