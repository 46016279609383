/**
 * @author Nick M.
 * @create date 2024-09-09
 * @desc Simple TextField with info title
 */

import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ContactsGroupML from '../../../model/ContactsGroups/ContactsGroupML';
import CustomDeletedChip from './CustomDeletedChip';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
    list: ContactsGroupML[],
    sx?: any,
    contactGroups?: ContactsGroupML[],
    onChange?: (event: any, newValue: any) => void,
    children?: React.ReactNode,
}

export default function TextFieldTags({ list, sx, contactGroups, onChange }: ComponentProps) {

    const { t } = useTranslation();

    const selecteed = list.filter((option) => contactGroups?.some(group => group._id === option._id));
    const [value, setValue] = React.useState(selecteed);

    return (
        <Autocomplete
            multiple
            id="fixed-tags-demo"
            value={value}
            onChange={(event, newValue) => {
                setValue([...newValue]);
                onChange && onChange(event, newValue)
            }}
            options={list}
            getOptionLabel={(option) => option.name}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => {
                    const { key, ...tagProps } = getTagProps({ index });
                    return (
                        <CustomDeletedChip
                            key={key}
                            label={option.name}
                            color={{ bg: option.color, text: option.color }}
                            tagProps={{ ...tagProps }}
                        />
                    );
                })
            }
            renderInput={(params) => (
                <TextField {...params} label={t('contacts.modal.groups.label')} placeholder={t('contacts.modal.groups.plhd')} sx={sx} />
            )}
        />
    );
}
