
import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useSearchParams } from "react-router-dom";
import BasicList from "./pages/MainPage/BasicList";
import './App.css';
import SignIn from "./pages/SignIn/SignIn";
import NoAuthorize from "./pages/NoAuthorize";
import { Box } from "@mui/material";
import rtlPlugin from 'stylis-plugin-rtl';
import { createTheme, ThemeProvider, Theme } from "@mui/material";
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { useTranslation } from "react-i18next";
import InstallApp from "./pages/InstallApp";

function App() {
 
const { i18n } = useTranslation();
 const dirType = i18n.language === 'ar' ? "rtl" : "ltr";
 const changeLanguage = (lang: string) => { i18n.changeLanguage(lang); };

  useEffect(() => { document.body.dir = dirType }, [i18n.language]);

  const cacheRtl = createCache({
    key: i18n.language === 'ar' ? 'muirtl' : 'muiltr',
    stylisPlugins: i18n.language === 'ar' ? [rtlPlugin] : [],
  });

  // FOR DEBUG
  const params = new URLSearchParams(window.location.search);
  const isShowLanguageMenu = params.get("l")

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={createTheme({ direction: dirType })}>
        {
          isShowLanguageMenu === '1' &&
          <Box sx={{ position: 'absolute', bottom: 0, zIndex: 9999, right: 0 }}>
            <button onClick={() => changeLanguage('en')}>English</button>
            <button onClick={() => changeLanguage('ar')}>Arabic</button>
          </Box>
        }
        <Router>
          <Routes>
            <Route path="*" element={<BasicList />} />
            <Route path="/notauth" element={<NoAuthorize />} />
            <Route path="/" element={<SignIn />} />
            <Route path="/installApp" element={ <InstallApp />} />
          </Routes>
        </Router>
       </ThemeProvider>
     </CacheProvider>
  );
}

export default App;
