import User from "../../model/User";
import { fetchGetData, fetchPutData } from "../../utils/restFuncs/fetch";
import AuthService from "./AuthService";
const SERVER_NOT_AVAILABLE = 'Server is unavailable, repeat later on';
const UAE_PASS_LOGOUT_URI = process.env.REACT_APP_UAE_PASS_LOGOUT_URI as string
const UAE_PASS_REDIRECT_URI = process.env.REACT_APP_UAE_PASS_REDIRECT_URI as string;

export default class AuthServiceRestImpl implements AuthService {
    url: string;
    constructor(secure: string, host: string) {
    this.url = `${secure}://${host}`;
}

async checkAuth() {
    const responseCheck = await fetch(`${this.url}/auth/check`, {
        method: 'GET',
        credentials: 'include',  
        });
        
        if (!responseCheck.ok) {
        throw new Error(`HTTP error! status: ${responseCheck.status}`);
    }
        return await responseCheck.json(); 
        
        }

    async logout() {
       const redirectAfterLogout = encodeURIComponent(UAE_PASS_REDIRECT_URI);
     //   window.location.href = `${UAE_PASS_LOGOUT_URI}?redirect_uri=${redirectAfterLogout}`;


        const redirLogout = `${UAE_PASS_LOGOUT_URI}?redirect_uri=${redirectAfterLogout}`;
        window.location.href = redirLogout;

     //  const stageURI = 'https://stg-id.uaepass.ae/idshub/logout?redirect_uri=https://marasimapi.dubai.gov.ae/auth/postlogout'
      // window.location.href = stageURI;
         
    };
  
 


async getUserData(id:string) {
return fetchGetData<User>(this.url, SERVER_NOT_AVAILABLE, `users/get/${id}`)
}


async updateLocations(id:string, locations:string[]) {
return fetchPutData<any>(this.url, locations, SERVER_NOT_AVAILABLE, `users/updatelocs/${id}`)

    }



    
   

}

   



