/**
 * @author Nick M.
 * @create date 2024-09-17
 * @desc [description]
 */

import React from 'react';
import { Typography } from '@mui/material';

interface ComponentProps {
    name?: string,
    ml?: number,
    mr?: number,
}

const CustomDataGridHeader: React.FC<ComponentProps> = ({ name, ml = 0, mr = 0 }) => {
    return (<><Typography sx={{ fontFamily: 'Zain', fontSize: 18, ml: ml, mr: mr }}>{name}</Typography></>);
};

export default CustomDataGridHeader;