import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export const formatToISOWithDayjs = (input: string) => {
    const sanitizedInput = input.replace(/[^\x00-\x7F]/g, '');
     const parsedDate = dayjs(sanitizedInput, 'MM/DD/YYYY hh:mm A');
     return parsedDate.toISOString();
}
