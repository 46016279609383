import AddIcon from '@mui/icons-material/Add';
import { Button } from "@mui/material";

type Props = {
  butName: string;
  onClickFn(): void;
}

const AddGuestsButton: React.FC<Props> = ({butName, onClickFn}) => {

  const styleButton = {
    fontFamily: 'Zain',
    fontSize: 20,
    borderRadius: '8px',
    textTransform: 'uppercase',
    letterSpacing: 0.46,
    wordWrap: 'break-word',
    backgroundColor: 'white',
    color: '#1E2282',
    border: '1px rgba(30, 34, 130, 0.50) solid',
    width: '280px',
    height: '38px'
  }

  return <Button startIcon={<AddIcon />} size="small" variant="contained" sx={styleButton}
    onClick={onClickFn}
  >
    {butName}
  </Button>

}

export default AddGuestsButton;

