import { Box, Typography } from "@mui/material";


type Props = {
    headerName: string;
    sx?: {};
}

const FormsHeaderName: React.FC<Props> = (props) => {
    const style = {
        color: 'var(--text-primary, rgba(0, 0, 0, 0.87))',
        fontFamily: 'Zain',
        fontSize: '32px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '120%',
        margin: '24px',
        marginButtom: '12vh',
        marginLeft: "2px",
        ...props.sx
        
        }

return <Box textAlign={'left'}>
<Typography sx={style}>{props.headerName}</Typography>
</Box>

}

export default FormsHeaderName;