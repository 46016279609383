/**
 * @author Nick M.
 * @create date 2024-09-09
 * @desc Default UI component, mostly used for close, delete functionality.
 */

import IconButton from '@mui/material/IconButton';
import { CloseRounded } from '@mui/icons-material';

export default function DefaultCloseButton({ onClick }: any) {
    return (
        <>
            <IconButton
                onClick={onClick}
                sx={style}
                color="default"
                size="medium"
            >
                <CloseRounded />
            </IconButton >
        </>
    );
}

const style = {
    width: 32,
    height: 32
};