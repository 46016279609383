import { createSlice } from "@reduxjs/toolkit";
import Evnt from "../../model/Event/Evnt";

function getEvent(): Evnt | undefined {
    let res: Evnt|undefined;
    return res;
}

const initialState: {evnt: Evnt} = {
    evnt: getEvent()!
}

const eventSlice = createSlice({
    initialState,
    name: 'eventSlice',
    reducers: {
        set: (state, data) => {
            state.evnt = data.payload;
        },
        reset: (state) => {
            state.evnt = undefined!;


        }
    }
});
export const eventActions = eventSlice.actions;
export const eventReducer = eventSlice.reducer;

