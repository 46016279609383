import { Box, Card, CircularProgress } from "@mui/material"
import FormsHeaderName from "../../../../components/Forms/FormsHeaderName";
import MainBackgroundContainer from "../../CommonComponentsUI/MainBackgroundContainer";
import { useTranslation } from 'react-i18next';

const CreateEvSpinner: React.FC = () => {
  const { t } = useTranslation();

  return (
        <MainBackgroundContainer>
          <Card sx={{
            width: 'calc(100% - 16px)',
            maxWidth: '580px',
            height: '250px',
            ml: '16px',
            marginTop: '0px',
            borderRadius: "16px",
            display: 'flex',
            alignItems: 'center', 
            justifyContent: 'center' }}>
            <Box display='flex' alignItems='center' flexDirection='column' gap='20px'>
              <CircularProgress sx={{ color: "#2E7D32" }} />
              <FormsHeaderName headerName={t('spinner.header.crnewev')} />
            </Box>
          </Card>
          </MainBackgroundContainer>
      );
}
export default CreateEvSpinner;